import React, { useCallback, useEffect, useState } from 'react';

import { LineItem, DraftCustomOrder } from '#types';

import useProducts from '#hooks/useProducts';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import { formatCurrency } from '#utils/currency';
import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.lineItems;

const TABLE_KEYS = {
  id : 'id',
  sku : 'sku',
  product : 'product',
  quantity : 'quantity',
  unit : 'unit',
  price : 'price',
  actions : 'actions',
} as const;
export type TableKey = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defaultTableKeys = Object.values(TABLE_KEYS);

interface ItemListProps {
  order? : DraftCustomOrder;
  lineItems? : LineItem[];
  tableKeys? : TableKey[];
  generateActions? : (lineItem : LineItem) => CellElement;
  showFulfilmment? : boolean;
  mergeProducts? : boolean;
  mergePricing? : boolean;
}

function ItemList({
  order,
  lineItems,
  tableKeys = defaultTableKeys,
  showFulfilmment = true,
  mergeProducts = false,
  mergePricing = false,
} : ItemListProps) {
  const merging = mergeProducts || mergePricing;

  const { products } = useProducts();

  const [head, setHead] = useState<React.ReactNode>([]);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const generateHead = useCallback(() => {
    const keys = merging ? tableKeys.filter((key) => (
      key !== TABLE_KEYS.price && key !== TABLE_KEYS.unit
    )) : tableKeys;
    setHead(
      <>
        { keys.map((key) => {
          switch(key) {
            case TABLE_KEYS.id:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.id) }
              </TableCell>);
            case TABLE_KEYS.sku:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.productSku) }
              </TableCell>);
            case TABLE_KEYS.product:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.product) }
              </TableCell>);
            case TABLE_KEYS.quantity:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.quantity) }
              </TableCell>);
            case TABLE_KEYS.price:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.price) }
              </TableCell>);
            default: return (<TableCell key={key} />);
          }
        }) }
      </>
    );
  }, [tableKeys, merging]);

  const generateRows = useCallback(() => {
    const keys = merging ? tableKeys.filter((key) => (
      key !== TABLE_KEYS.price && key !== TABLE_KEYS.unit
    )) : tableKeys;

    const allItems = lineItems || (order ? Object.values(order.lineItems) : []);
    const selectionItems = order
      ? Object.values(order.selections).map((selection) : LineItem => {
        const item = allItems.find((i) => i.id === selection.lineItemId);
        return {
          customerId : order.customer?.id ?? null,
          serviceChannelId : order.serviceChannel?.id ?? null,
          locationId : order.location?.id ?? null,
          addressId : order.address?.id ?? null,
          timeSlotId : order.timeSlot?.id ?? null,
          timeSlotIteration : order.timeSlotIteration ?? null,
          timeSlotDivision : order.timeSlotDivision ?? null,
          price : {
            amount : 0,
            currencyCode : 'CAD',
            increment : 0,
            calculatedValue : 0,
          },
          ...item,
          productId : selection.productId,
          quantity : selection.quantity,
        }
      })
      : [];
    const items = merging
      ? [...allItems, ...selectionItems].reduce((acc, lineItem) => {
        const existing = acc.find((item) => (
          item.productId === lineItem.productId
            && (mergePricing || (
              (item.price?.amount === lineItem.price?.amount)
                && (item.price?.currencyCode === lineItem.price?.currencyCode)
            )
          )
        ));
        if (existing) {
          existing.quantity += lineItem.quantity;
        } else {
          acc.push({ ...lineItem });
        }
        return acc;
      }, [] as LineItem[])
      : allItems;

    setRows(items.map((lineItem, i) => {
      const fulfilment = (!merging && showFulfilmment && order?.order)
        ? Object.values(order?.order?.fulfilments).find(
          (f) => f.lineItemId === lineItem.id,
        ) : undefined;

      const product = listRecords(products).find(
        (p) => p.id === (fulfilment?.fulfilledProductId
          || fulfilment?.requestedProductId
          || lineItem.productId),
      );
      const price = fulfilment?.price
        ?? lineItem.price
        ?? product?.price
        ?? null;

      return (
        <React.Fragment key={`${lineItem.id}`}>
          { keys.map((key) => {
            switch(key) {
              case TABLE_KEYS.id:
                return (<TableCell key={key}>
                  { lineItem.id
                    + (fulfilment?.id ? `-${fulfilment?.id}` : '') }
                </TableCell>);
              case TABLE_KEYS.sku:
                return (<TableCell key={key}>
                  { product?.sku }
                </TableCell>);
              case TABLE_KEYS.product:
                return (<TableCell key={key}>
                  { product?.name
                    ?? localize(localeTableKeys.defaults.product) }
                </TableCell>);
              case TABLE_KEYS.quantity:
                return (<TableCell key={key}>
                  { lineItem.quantity }
                </TableCell>);
              case TABLE_KEYS.price:
                return (<TableCell key={key}>
                  { price ? formatCurrency(price) : null }
                </TableCell>);
              default: return (<TableCell key={key} />);
            }
          } )}
        </React.Fragment>
      )
    }));
  }, [
    lineItems,
    order,
    tableKeys,
    showFulfilmment,
    mergePricing,
    merging,
    products,
  ]);

  useEffect(() => { generateHead() }, [generateHead]);
  useEffect(() => { generateRows() }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
    />
  );
}

export default ItemList;
