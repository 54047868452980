import React, { useCallback, useEffect, useState } from 'react';

import { Product, Tag } from '#types';

import useNavigation from '#hooks/useNavigation';
import useTags from '#hooks/useTags';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Text from '#materials/Text';
import Button from '#materials/Button';
import { TableActionCell, Action } from '#materials/TableCell';
import Icon from '#materials/Icon';

import Section from '#components/dashboard/Section';
import ProductTable from '#components/products/ProductTable';
import ProductSearch from '#components/products/ProductSearch';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.tags.tagProducts;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.products;

interface TagProductsProps {
  tag : Tag;
}

function TagProducts({ tag } : TagProductsProps) {
  const  { navigate } = useNavigation();
  const {
    addProductToTag,
    getTagProducts,
    moveProductToTop,
    moveProductToBottom,
    moveProductUp,
    moveProductDown,
    removeProductFromTag,
  } = useTags();

  const [add, setAdd] = useState(false);
  const [
    tagProducts,
    setTagProducts
  ] = useState<Product[]>([]);

  const refreshTagProducts = useCallback(async () => {
    if (!tag.id) return;
    setTagProducts(getTagProducts(tag));
  }, [tag, getTagProducts]);

  const handleAdd = useCallback(() => {
    setAdd(true);
  }, []);

  const handleClose = useCallback(() => {
    setAdd(false);
  }, []);

  const handleView = useCallback((product : Product) => () => {
    if (!product.id) return;
    navigate(`/products/${product.id}`);
  }, [navigate]);

  const handleAddProduct = useCallback((product : Product) => async () => {
    const success = await addProductToTag(tag, product);
    if (success) {
      refreshTagProducts();
    }
  }, [addProductToTag, tag, refreshTagProducts]);

  const handleMoveUp = useCallback((product : Product ) => async () => {
    const success = await moveProductUp(tag, product);
    if (success) {
      refreshTagProducts();
    }
  }, [tag, moveProductUp, refreshTagProducts]);

  const handleMoveDown = useCallback((product : Product ) => async () => {
    const success = await moveProductDown(tag, product);
    if (success) {
      refreshTagProducts();
    }
  }, [tag, moveProductDown, refreshTagProducts]);

  const handleMoveFirst = useCallback((product : Product ) => async () => {
    const success = await moveProductToTop(tag, product);
    if (success) {
      refreshTagProducts();
    }
  }, [tag, moveProductToTop, refreshTagProducts]);

  const handleMoveLast = useCallback((product : Product ) => async () => {
    const success = await moveProductToBottom(tag, product);
    if (success) {
      refreshTagProducts();
    }
  }, [tag, moveProductToBottom, refreshTagProducts]);

  const handleRemoveProduct = useCallback((product : Product) => async () => {
    await removeProductFromTag(product, tag);
  }, [removeProductFromTag, tag]);

  const filterAvailableProducts = useCallback((product : Product) => {
    return !!product.id && !tag.productIds.includes(product.id);
  }, [tag]);

  const generateRemoveActions = useCallback((product : Product) => {
    const currentIndex = tagProducts.indexOf(product);
    const firstIndex = currentIndex === 0;
    const lastIndex = currentIndex === tagProducts.length-1;
    return (add
      ? <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.moveTop)}
          onClick={handleMoveFirst(product)}
          disabled={firstIndex}
          colour={settings.colours.button.primary}
        >
          <Icon icon={settings.svgIcons.keyboardDoubleArrowUp} />
        </Action>
        <Action
          label={localize(localeTableKeys.actions.moveUp)}
          onClick={handleMoveUp(product)}
          disabled={firstIndex}
          colour={settings.colours.button.primary}
        >
          <Icon icon={settings.svgIcons.keyboardArrowUp} />
        </Action>
        <Action
          label={localize(localeTableKeys.actions.moveDown)}
          onClick={handleMoveDown(product)}
          disabled={lastIndex}
          colour={settings.colours.button.primary}
        >
          <Icon icon={settings.svgIcons.keyboardArrowDown} />
        </Action>
        <Action
          label={localize(localeTableKeys.actions.moveBottom)}
          onClick={handleMoveLast(product)}
          disabled={lastIndex}
          colour={settings.colours.button.primary}
        >
          <Icon icon={settings.svgIcons.keyboardDoubleArrowDown} />
        </Action>
        <Action
          label={localize(localeTableKeys.actions.removeProduct)}
          onClick={handleRemoveProduct(product)}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.remove} />
        </Action>
      </TableActionCell>
      : <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          onClick={handleView(product)}
        >
          <Icon icon={settings.svgIcons.shoppingBag} />
        </Action>
      </TableActionCell>
    )
  }, [
    add,
    tagProducts,
    handleRemoveProduct,
    handleView,
    handleMoveFirst,
    handleMoveLast,
    handleMoveUp,
    handleMoveDown,
  ]);

  const generateAddActions = useCallback((product : Product) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.addProduct)}
          onClick={handleAddProduct(product)}
        >
          <Icon icon={settings.svgIcons.add} />
        </Action>
      </TableActionCell>
    );
  }, [handleAddProduct]);

  useEffect(() => { refreshTagProducts(); }, [tag, refreshTagProducts]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
    >
      { tagProducts.length
        ? <ProductTable
          products={tagProducts}
          generateActions={generateRemoveActions}
        />
        : <Text>{ localize(localeContentKeys.noProducts) }</Text>
      }
      { add
        ? <>
          <Segment title={localize(localeContentKeys.availableProducts)}>
            <ProductSearch
              pageCount={5}
              filter={filterAvailableProducts}
              generateActions={generateAddActions}
            />
            <Button
              onClick={handleClose}
              colour={settings.colours.button.alert}
            >
              { localize(localeButtonKeys.close) }
            </Button>
          </Segment>
        </>
        : <Button
          onClick={handleAdd}
        >
          { localize(localeButtonKeys.add) }
        </Button>
      }
    </Section>
  );
}

export default TagProducts;
