import React, { useCallback } from 'react';

import { Product } from '#types';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import { formatCurrency } from '#utils/currency';
import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.tables.products;

export const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  sku : 'sku',
  price : 'price',
  actions : 'actions',
} as const;
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defaultTableKeys = Object.values(TABLE_KEYS);

interface ProductTableProps {
  products : Product[];
  tableKeys? : TableKeys[];
  pageCount? : number;
  generateActions? : (product : Product) => CellElement;
  fadeProducts? : (product : Product) => boolean;
}

function ProductTable({
  products,
  tableKeys = defaultTableKeys,
  pageCount = 20,
  generateActions,
  fadeProducts,
} : ProductTableProps) {

  const generateHead = useCallback(() => {
    return <>
      { tableKeys.map((key) => {
        switch(key) {
          case TABLE_KEYS.id:
            return <TableCell key={key}>
              { localize(localKeys.headings.id) }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={key}>
              { localize(localKeys.headings.name) }
            </TableCell>;
          case TABLE_KEYS.sku:
            return <TableCell key={key}>
              { localize(localKeys.headings.sku) }
            </TableCell>;
          case TABLE_KEYS.price:
            return <TableCell key={key}>
              { localize(localKeys.headings.price) }
            </TableCell>;
          default: return <TableCell key={key} />;
        }
      }) }
    </>
  }, [tableKeys]);

  const generateRow = useCallback((product : Product) => {
    return <>
      { tableKeys.map((key) => {
        const fade = fadeProducts?.(product);
        switch(key) {
          case TABLE_KEYS.id:
            return <TableCell key={key} faded={fade}>
              { `# ${product.id}` }
            </TableCell>;
          case TABLE_KEYS.name:
            return <TableCell key={key} faded={fade}>
              { product.name }
            </TableCell>;
          case TABLE_KEYS.sku:
            return <TableCell key={key} faded={fade}>
              { product.sku }
            </TableCell>;
          case TABLE_KEYS.price:
            return <TableCell key={key} faded={fade}>
              <div>{ formatCurrency(product.price) }</div>
            </TableCell>;
          case TABLE_KEYS.actions:
            return (
              <React.Fragment key={key}>
                { generateActions?.(product) }
              </React.Fragment>
            );
          default: return <TableCell key={key} />;
        }
      }) }
    </>
  }, [tableKeys, generateActions, fadeProducts]);

  return (
    <Table
      head={generateHead()}
      rows={products.map((product) => (
        <React.Fragment key={`product-table-row-${product.id}`}>
          { generateRow(product) }
        </React.Fragment>
      ))}
      pageCount={pageCount}
    />
  );
}

export default ProductTable;
