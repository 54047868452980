import React, { useCallback, useState} from 'react';

import AssemblyForm from '#components/assemblies/AssemblyForm';

import { FormProvider } from '#context/FormContext';

import useForm from '#hooks/useForm';
import useNotifications from '#hooks/useNotifications';

import { settings } from '#materials';
import Button from '#materials/Button';
import Icon from '#materials/Icon';

import { Assembly, Product } from '#types';
import locale, { localize } from '#utils/locale';
import useOptions from '#hooks/useOptions';

const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.assemblies;

interface CreateAssemblyProps {
  product : Product;
  onSave? : (assembly : Assembly) => void;
  onCancel? : () => void;
}

interface CreateAssemblyControlProps extends CreateAssemblyProps {
  assembly : Assembly;
}

const CreateAssemblyControl = ({
  assembly : init,
  product,
  onSave,
  onCancel,
} : CreateAssemblyControlProps) => {
  const { createNotification } = useNotifications();
  const { createProductAssembly } = useOptions();

  const { state: assembly, reset } = useForm<Assembly>();

  const handleSave = useCallback(async () => {
    if (!assembly || !product) return;

    const newAssembly = await createProductAssembly(product, assembly);

    if (newAssembly) {
      createNotification({
        key: 'update-assembly-success',
        message: localize(localeNotificationKeys.updated.success),
        colour: settings.colours.alert.primary,
        icon: <Icon icon={settings.svgIcons.shoppingBag} />,
      })
      onSave?.(newAssembly);
    } else {
      createNotification({
        key: 'update-assembly-error',
        message: localize(localeNotificationKeys.updated.error),
        colour: settings.colours.alert.alert,
        icon: <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    }
  }, [product, onSave, assembly, createNotification, createProductAssembly]);

  const handleCancel = useCallback(() => {
    reset();
    onCancel?.();
  }, [reset, onCancel]);

  return (
    <>
      <Button
        onClick={handleSave}
        disabled={!assembly?.name}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
      <AssemblyForm assembly={assembly || init} />
    </>
  );
}

const CreateAssembly = ({ product } : CreateAssemblyProps) => {
  const { generateDefaultAssembly } = useOptions();

  const [assembly] = useState(generateDefaultAssembly());

  return (
    <FormProvider init={assembly} editing={true}>
      <CreateAssemblyControl assembly={assembly} product={product} />
    </FormProvider>
  );
};

export default CreateAssembly;
