import React, { useCallback, useState, useMemo } from 'react';

import { Assembly, Product } from '#types';

import { FormProvider } from '#context/FormContext';

import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useProducts from '#hooks/useProducts';
import useOptions from '#hooks/useOptions';

import { settings } from '#materials';
import Split from '#materials/Splt';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import IconButton from '#materials/IconButton';
import Banner from '#materials/Banner';
import Segment from '#materials/Segment';
import Tabs from '#materials/Tabs';

import AssemblyForm from '#components/assemblies/AssemblyForm';
import CollectionDetails from '#components/assemblies/CollectionDetails';
import CreateCollection from '#components/assemblies/CreateCollection';

import { listRecords } from '#utils/data';
import { formatDate, shiftLocalDateTime, formats } from '#utils/date';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.assemblies.assemblyDetails;
const localeFormKeys = locale.keys.forms.assemblies;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.assemblies;

interface AssemblyDetailsProps {
  product : Product;
  assembly : Assembly;
  onSave? : (assembly : Assembly) => void;
}

function AssemblyDetailsControl({
  product,
  assembly : init,
  onSave,
} : AssemblyDetailsProps) {
  const { createNotification } = useNotifications();

  const {
    state : assembly,
    reset,
    editing,
    setEditing,
  } = useFormContext<Assembly>();
  const { products } = useProducts();
  const {
    updateAssembly,
    deleteAssembly,
    removeAssemblyFromProduct,
    moveAssemblyUp,
    moveAssemblyDown,
    getProductAssemblies,
    getAssemblyProducts,
    getAssemblyCollections,
  } = useOptions();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!assembly) return;

    const updatedAssembly = await updateAssembly(assembly);
    if (updatedAssembly) {
      createNotification({
        key : 'update-assembly-success',
        message : localize(localeNotificationKeys.updated.success),
        colour : settings.colours.alert.primary,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
      if (onSave) onSave(updatedAssembly);
      setEditing(false);
    } else {
      createNotification({
        key : 'update-assembly-error',
        message : localize(localeNotificationKeys.updated.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    }
  }, [onSave, assembly, setEditing, updateAssembly, createNotification]);

  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset, setEditing]);

  const handleRemove = useCallback(async () => {
    if (!product || !assembly) return;
    const success = await removeAssemblyFromProduct(product, assembly);
    if (success) {
      createNotification({
        key : 'remove-assembly-success',
        message : localize(localeNotificationKeys.removed.success),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-assembly-error',
        message : localize(localeNotificationKeys.removed.error),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [
    product,
    assembly,
    removeAssemblyFromProduct,
    createNotification,
  ]);

  const handleDelete = useCallback(async () => {
    if (!assembly) return;
    const success = await deleteAssembly(assembly);
    if (success) {
      createNotification({
        key : 'delete-assembly-success',
        message : localize(localeNotificationKeys.deleted.success),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-assembly-error',
        message : localize(localeNotificationKeys.deleted.error),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [assembly, deleteAssembly, createNotification]);

  const handleMoveUp = useCallback(async () => {
    if (!assembly) return;
    const success = await moveAssemblyUp(product, assembly);
    if (success) {
      createNotification({
        key : 'move-assembly-up-success',
        message : localize(localeNotificationKeys.moved.success),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'move-assembly-up-error',
        message : localize(localeNotificationKeys.moved.error),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [product, assembly, moveAssemblyUp, createNotification]);

  const handleMoveDown = useCallback(async () => {
    if (!assembly) return;
    const success = await moveAssemblyDown(product, assembly);
    if (success) {
      createNotification({
        key : 'move-assembly-down-success',
        message : localize(localeNotificationKeys.moved.success),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'move-assembly-down-error',
        message : localize(localeNotificationKeys.moved.error),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [product, assembly, moveAssemblyDown, createNotification]);

  const handleInitRemove = useCallback(
    () => setConfirmRemove(true),
    [setConfirmRemove]
  );

  const handleCancelRemove = useCallback(
    () => setConfirmRemove(false),
    [setConfirmRemove]
  );

  const initDelete = useCallback(
    () => setConfirmDelete(true),
    [setConfirmDelete]
  );

  const cancelDelete = useCallback(
    () => setConfirmDelete(false),
    [setConfirmDelete]
  );

  const usedElsewhere = useMemo(() => (init.productIds.length > 1), [init]);
  const otherProducts = useMemo(() => (
    (usedElsewhere
      ? listRecords(products).filter((p) => p.id
        && init.productIds.includes(p.id)
        && p.id !== product.id)
      : []).slice(0, 3)
  ), [products, product, init, usedElsewhere]);

  const collectionTabs = useMemo(() => {
    return [
      ...getAssemblyCollections(init).map((collection) => {
        const start = collection.starting;
        const end = collection.ending
          ? shiftLocalDateTime(collection.ending, -86400000)
          : null;

        let tabName;
        if (!start && !end) {
          tabName = 'Default Collection';
        } else if (!start && end) {
          tabName = `Until ${formatDate(end, formats.easy)}`;
        } else if (start && !end) {
          tabName = `From ${formatDate(start, formats.easy)}`;
        } else if (start && end) {
          tabName = `${formatDate(start, formats.easy)} - `
            + formatDate(end, formats.easy);
        } else {
          tabName = 'Collection';
        }

        return {
          tabName : `${tabName} (#${collection.id})`,
          tabContent : <CollectionDetails
            key={collection.id}
            product={product}
            assembly={init}
            collection={collection}
          />,
        }
      }),
      {
        tabName : localize(localeButtonKeys.new),
        tabContent : <CreateCollection assembly={init} />,
        tabIcon : <Icon icon={settings.svgIcons.add} />
      }
    ];
  }, [init, product, getAssemblyCollections]);

  const isFirst = getProductAssemblies(product).indexOf(init) === 0;
  const isLast = getProductAssemblies(product).indexOf(init)
    === getProductAssemblies(product).length - 1;

  return (
    <>
      { (usedElsewhere && !confirmRemove && !confirmDelete) && (
        <Banner
          colour={settings.colours.alert.secondary}
          icon={(<Icon icon={settings.svgIcons.info} />)}
        >
          { localize(localeContentKeys.usedElsewhere) + ' '
            + otherProducts.map((p) => ` ${p.name}`).join(', ')
            + (init.productIds.length > 3 ? '...' : '.') }
        </Banner>
      ) }
      { confirmRemove && (
        <Banner
          onClose={handleCancelRemove}
          colour={settings.colours.alert.alert}
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={
            <>
              <Button onClick={handleRemove}>
                { localize(localeButtonKeys.remove) }
              </Button>
              <Button onClick={handleCancelRemove}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          }
        >
          { localize(localeContentKeys.confirmRemove) }
        </Banner>
      ) }
      { confirmDelete && (
        <Banner
          onClose={cancelDelete}
          colour={settings.colours.alert.alert}
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={
            <>
              <Button onClick={handleDelete}>
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button onClick={cancelDelete}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          }
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { editing
        ? (
          <>
            <Button
              onClick={handleSave}
            >
              { localize(localeButtonKeys.save) }
            </Button>
            <Button
              onClick={handleCancel}
              colour={settings.colours.button.alert}
            >
              { localize(localeButtonKeys.cancel) }
            </Button>
          </>
        ) : (
          <Split
            left={(
              <>
                <Button
                  onClick={handleEdit}
                >
                  { localize(localeButtonKeys.edit) }
                </Button>
                <Button
                  onClick={handleInitRemove}
                  colour={settings.colours.button.alert}
                  disabled={confirmDelete || confirmRemove}
                >
                  { localize(localeButtonKeys.remove) }
                </Button>
                { !usedElsewhere && (
                  <Button
                    onClick={initDelete}
                    colour={settings.colours.button.alert}
                    disabled={confirmDelete || confirmRemove}
                  >
                    { localize(localeButtonKeys.delete) }
                  </Button>
                ) }
              </>
            )}
            right={(
              <>
                <IconButton
                  label={localize(localeButtonKeys.moveLeft)}
                  onClick={handleMoveUp}
                  disabled={confirmDelete || isFirst}
                >
                  <Icon icon={settings.svgIcons.chevronLeft} />
                </IconButton>
                <IconButton
                  label={localize(localeButtonKeys.moveRight)}
                  onClick={handleMoveDown}
                  disabled={confirmDelete || isLast}
                >
                  <Icon icon={settings.svgIcons.chevronRight} />
                </IconButton>
              </>
            )}
          />
        )
      }
      <AssemblyForm
        assembly={assembly || init}
        onSubmit={handleSave}
      />
      <Segment title={localize(localeContentKeys.collections)} />
      <Tabs tabObjects={collectionTabs} />
    </>
  );
}

function AssemblyDetails({ assembly, ...props } : AssemblyDetailsProps) {
  const validate = useCallback((assembly : Assembly) => {
    const errors : { [key : string] : string } = {};

    if (!assembly.name) {
      errors.name = localize(localeFormKeys.errors.invalidName);
    }

    return errors;
  }, []);

  return (
    <FormProvider
      init={assembly}
      validators={[validate]}
      editingInit={false}
    >
      <AssemblyDetailsControl
        assembly={assembly}
        {...props}
      />
    </FormProvider>
  );
}

export default AssemblyDetails;
