import React, { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { Spacing, Dimension, convert, settings } from '#materials';
import FlexItem from '#materials/FlexItem';

interface SelectProps<T> {
  label : string;
  selected : T | null;
  options : T[];
  onChange : (value : T | null) => void;
  onBlur? : () => void;
  errors? : string[];
  disableClear? : boolean;
  labelGenerator : (value : T | null) => string;
  keyGenerator? : (value : T | null) => string;
  isEqual? : (a : T | null, b : T | null) => boolean;
  renderer? : (value : T | null) => React.ReactNode;
  search? : (value : string) => Promise<T[] | null>;
  hideErrors? : boolean;
  width? : Dimension;
  spacing? : Spacing;
  disabled? : boolean;
}

function Select<T>({
  label,
  selected,
  options : allOptions,
  onChange,
  onBlur,
  errors,
  disableClear = false,
  labelGenerator,
  keyGenerator,
  isEqual,
  renderer,
  search,
  hideErrors = false,
  width = settings.dimensions.full,
  spacing = settings.spacings.medium,
  disabled,
} : SelectProps<T>) {
  const [filteredOptions, setFilteredOptions] = useState<T[]>(allOptions);
  const [input, setInput] = useState<string>('');

  const contains = useCallback((options : T[], value : T) => {
    return options.find(i => isEqual ? isEqual(i, value) : i === value);
  }, [isEqual]);

  const handleInput = useCallback((event : any, newInputValue : string) => {
    setInput(newInputValue);
  }, []);

  const handleOption = useCallback((event : any, newValue : T | null) => {
    if (onChange) onChange(newValue);
  }, [onChange]);

  useEffect(() => {
    async function searchOptions() {
      const results = search ? await search(input) : null;
      setFilteredOptions(results && results.length ? results : allOptions)
    }
    searchOptions();
  }, [input, allOptions, search]);

  function renderOption(
    props : React.HTMLAttributes<HTMLLIElement>,
    value : T | null,
  ) {
    return (
      <Box
        component="li"
        {...props}
        key={keyGenerator ? keyGenerator(value) : labelGenerator(value)}
      >
        { renderer ? renderer(value) : labelGenerator(value) }
      </Box>
    );
  }

  const safeOptions = selected === null || contains(filteredOptions, selected)
    ? filteredOptions
    : allOptions

  return (
    <FlexItem
      width={settings.dimensions.full}
      maxWidth={width}
    >
      <Box
        sx={{
          minHeight : 38.8,
          mx : spacing !== settings.spacings.dense ? [1] : [0],
          my : spacing !== settings.spacings.dense ? [1] : [0.6],
        }}
      >
        <Autocomplete<T | null, false, boolean>
          value={selected}
          options={safeOptions}
          inputValue={input}
          onInputChange={handleInput}
          onChange={handleOption}
          onBlur={onBlur}
          disableClearable={false}
          disabled={disabled}
          disablePortal
          {...(search && { filterOptions : i => i })}
          getOptionLabel={labelGenerator}
          isOptionEqualToValue={isEqual}
          size={spacing !== settings.spacings.dense ? "medium" : "small"}
          renderInput={(params) => <TextField
            {...params}
            label={label}
            error={!hideErrors && !!errors?.length}
            helperText={(!hideErrors && errors?.length)
              ? errors?.join('. ') + '.'
              : null
            }
          />}
          renderOption={renderOption}
          sx={{
            minWidth : convert.width(settings.dimensions.full),
            "& .MuiInputBase-input.Mui-disabled" : {
              WebkitTextFillColor : "#444",
            },
            ...(spacing === settings.spacings.dense && {
              "& .MuiInputBase-root" : {
                fontSize : "0.875rem",
              }
            }),
          }}
        />
      </Box>
    </FlexItem>
  );
}

export default Select;
