import React, { useCallback, useEffect, useState } from 'react';

import { Fulfilment, LineItem, Selection, DraftCustomOrder } from '#types';

import Table from '#materials/Table';
import { CellElement } from '#materials/TableCell';

import SelectionRow, {
  SELECTION_TABLE_KEYS,
  SelectionTableKey,
  defaultSelectionTableKeys,
} from '#components/selections/SelectionRow';
import { OrderFormMode } from '#components/orders/OrderForm';

export type { SelectionTableKey as TableKey };
export { SELECTION_TABLE_KEYS, defaultSelectionTableKeys };

interface SelectionTableProps {
  lineItem : LineItem;
  selections : Selection[];
  setSelection? : (selection : Selection) => void;
  order? : DraftCustomOrder;
  mode? : OrderFormMode;
  disabled? : boolean;
  updateFulfilment? : (fulfilment : Fulfilment) => void | Promise<void>;
  generateActions? : (selection : Selection) => CellElement;
  extraRow? : React.ReactNode;
  tableKeys? : SelectionTableKey[];
}

function SelectionTable({
  lineItem,
  selections,
  order,
  mode,
  extraRow,
  disabled = false,
  tableKeys = defaultSelectionTableKeys,
  setSelection,
  updateFulfilment,
  generateActions,
} : SelectionTableProps) {
  const generateRows = useCallback(() => {
    const rows : React.ReactNode[] = [];
    selections.forEach((selection) => {
      rows.push(
        <SelectionRow
          key={`${selection.id}`}
          lineItem={lineItem}
          selection={selection}
          order={order}
          tableKeys={tableKeys}
          disabled={disabled}
          mode={mode}
          setSelection={setSelection}
          onUpdateFulfilment={updateFulfilment}
          generateActions={generateActions}
        />
      )
    });
    if (extraRow) {
      rows.push(<React.Fragment key={'extra'}>{ extraRow }</React.Fragment>);
    }
    return rows;
  }, [
    lineItem,
    selections,
    order,
    tableKeys,
    extraRow,
    disabled,
    mode,
    setSelection,
    updateFulfilment,
    generateActions,
  ]);

  const [rows, setRows] = useState<React.ReactNode[]>(generateRows());

  useEffect(() => { setRows(generateRows()) }, [generateRows]);

  return (
    <Table
      head={null}
      rows={rows}
    />
  );
}

export default SelectionTable;
