import { useCallback, useContext } from 'react';

import {
  Address,
  Customer,
  Service,
  ServiceChannel,
  TimeSlot,
  Schedule,
  Location,
  Route,
  Product,
  FulfilmentStatus,
  LineItem,
  Fulfilment,
  DraftOrder,
  isLineItem,
} from '#mrktbox/clerk/types';

import OrderContext from '#mrktbox/clerk/context/OrderContext';

import useCustomers from '#mrktbox/clerk/hooks/useCustomers';
import useServices from '#mrktbox/clerk/hooks/useServices';
import useScheduling from '#mrktbox/clerk/hooks/useScheduling';
import useRoutes from '#mrktbox/clerk/hooks/useRoutes';
import useProducts from '#mrktbox/clerk/hooks/useProducts';

import { listRecords } from '#mrktbox/clerk/utils/data';
import { generatePath, generateUrl } from '#mrktbox/clerk/utils/nav';

export function draftEmptyOrder() : DraftOrder {
  return {
    customer : null,
    service : null,
    serviceChannel : null,
    location : null,
    address : null,
    timeSlot : null,
    timeSlotIteration : 0,
    timeSlotDivision : 0,
    time : null,
    lineItems : {},
    totals : [],
    complete : false,
    errors : [],
  };
}

function filterAddresses(
  addresses? : Address[] | null,
  filter? : {
    customer? : Customer | null,
    service? : Service | null,
    services? : Service[],
    location? : Location | null,
    getRoutes? : (address : Address) => Route[],
  },
) {
  const {
    customer,
    service,
    services,
    location,
    getRoutes,
  } = filter ?? {};
  if (location) return [];

  let filtered = (customer && customer.addresses)
    ? (addresses
      ? addresses.filter((address) => (
          address.id &&
          customer.addresses &&
          customer.addresses[address.id]
        ))
      : listRecords(customer.addresses))
    : (addresses ? [...addresses] : []);

  filtered = service
    ? filtered?.filter((address) => (
      getRoutes
        ? !!getRoutes(address).some((route) => (
          service.id && route.serviceIds?.includes(service.id)
        )) : []
    )) : (services
      ? filtered?.filter((address) => (
        getRoutes
          ? !!getRoutes(address).some((route) => (
            services.some((service) => (
              service.id && route.serviceIds?.includes(service.id)
            ))
          )) : []
      )) : filtered);

  return filtered;
}

function filterServiceChannels(
  serviceChannels : ServiceChannel[] | null,
  filter? : {
    service? : Service | null,
    services? : Service[],
    requireService? : boolean,
  },
) {
  if (!serviceChannels || !serviceChannels.length) return [];
  const { service, services, requireService = true } = filter ?? {};

  let filtered = [...serviceChannels];

  filtered = requireService
    ? filtered.filter((serviceChannel) => (
      !!serviceChannel.serviceIds?.length
    ))
    : filtered;

  filtered = service
    ? filtered.filter((serviceChannel) => (
      service.id &&
      serviceChannel.serviceIds &&
      serviceChannel.serviceIds.includes(service.id)
    ))
    : (services
      ? filtered.filter((serviceChannel) => (
        services.some((service) => (
          service.id &&
          serviceChannel.serviceIds &&
          serviceChannel.serviceIds.includes(service.id)
        ))
      ))
      : filtered);

  return filtered;
}

function filterServices(
  services : Service[] | null,
  filter? : {
    address? : Address | null,
    serviceChannel? : ServiceChannel | null,
    serviceChannels? : ServiceChannel[],
    location? : Location | null,
    locations? : Location[],
    schedules? : Schedule[],
    routes? : Route[],
    allRoutes? : Route[],
  },
) {
  if (!services || !services.length) return [];
  const {
    address,
    serviceChannel,
    serviceChannels,
    location,
    locations,
    schedules,
    routes,
    allRoutes,
  } = filter ?? {};

  let filtered = [...services];

  filtered = address
    ? filtered.filter((service) => (
      routes &&
      routes.some((route) => (
        service.id &&
        route.serviceIds?.includes(service.id)
      ))
    ))
    : filtered;

  filtered = serviceChannel
    ? filtered.filter((service) => (
      !serviceChannel || (
        service.id &&
        serviceChannel.serviceIds &&
        serviceChannel.serviceIds.includes(service.id)
      )
    ))
    : (serviceChannels
      ? filtered.filter((service) => (
        !serviceChannels ||
        serviceChannels.some((serviceChannel) => (
          service.id &&
          serviceChannel.serviceIds &&
          serviceChannel.serviceIds.includes(service.id)
        ))
      ))
      : filtered);

  if (location && allRoutes) {
    filtered = filtered.filter((service) => (
      !allRoutes.some((route) => (
        service.id
          && route.serviceIds?.includes(service.id)
      ))
    ));
  }

  filtered = location
    ? filtered.filter((service) => (
      !location || (
        service.id &&
        location.serviceIds &&
        location.serviceIds.includes(service.id)
      )
    ))
    : ((locations && routes)
      ? filtered.filter((service) => (
        (!locations || !routes) ||
        locations?.some((location) => (
          service.id &&
          location.serviceIds &&
          location.serviceIds.includes(service.id)
        )) ||
        routes?.some((route) => (
          service.id && route.serviceIds?.includes(service.id)
        ))
      )) : filtered);

  filtered = schedules
    ? filtered.filter((service) => (
      !schedules ||
      schedules.some((schedule) => (
        service.id &&
        schedule.serviceIds.includes(service.id)
      ))
    ))
    : filtered;

  return filtered;
}

function filterLocations(
  locations : Location[] | null,
  filter? : {
    service? : Service | null,
    services? : Service[],
    allRoutes? : Route[],
  },
) {
  if (!locations || !locations.length) return [];
  const { service, services, allRoutes } = filter ?? {};

  let filtered = [...locations];

  filtered = service
    ? locations.filter((location) => (
      location.id &&
      service?.id && (
        location.serviceIds &&
        location.serviceIds.includes(service.id) && (
          !allRoutes ||
          !allRoutes.some((route) => (
            service.id && route.serviceIds?.includes(service.id)
          ))
        )
      )
    ))
    : (services
      ? locations.filter((location) => (
        !services || (
          services.some((service) => (
            service.id &&
            location.serviceIds &&
            location.serviceIds.includes(service.id) && (
              !allRoutes ||
              !allRoutes.some((route) => (
                service.id && route.serviceIds?.includes(service.id)
              ))
            )
          ))
        )
      ))
      : filtered);

  return filtered;
}

function filterSchedules(
  schedules : Schedule[] | null,
  filter? : {
    service? : Service | null,
    services? : Service[],
    timeSlot? : TimeSlot | null,
  },
) {
  if (!schedules || !schedules.length) return [];
  const { service, services, timeSlot } = filter ?? {};

  let filtered = [...schedules];

  filtered = timeSlot
    ? schedules.filter((schedule) => (
      timeSlot.id &&
      listRecords(schedule.timeSlots).some(ts => ts.id === timeSlot.id)
    ))
    : filtered;

  filtered = service
    ? schedules.filter((schedule) => (
      service.id &&
      schedule.serviceIds.includes(service.id)
    ))
    : (services
      ? schedules.filter((schedule) => (
        !services ||
        services.some((service) => (
          service.id &&
          schedule.serviceIds.includes(service.id)
        ))
      ))
      : filtered);

  return filtered;
}

function filterTimeSlots(
  timeSlots? : TimeSlot[] | null,
  filter? : { schedule? : Schedule, schedules? : Schedule[] },
) {
  const { schedule, schedules } = filter ?? {};

  return (timeSlots && timeSlots.length)
    ? timeSlots.filter((timeSlot) => (
      schedule
        ? listRecords(schedule.timeSlots).some(ts => ts.id === timeSlot.id)
        : (schedules && schedules.some((schedule) => (
          listRecords(schedule.timeSlots).some(ts => ts.id === timeSlot.id)
        )))
    )) : schedule
      ? listRecords(schedule.timeSlots)
      : schedules
        ? listRecords(schedules.reduce((acc, schedule) => (
          { ...acc, ...schedule.timeSlots }
        ), {} as { [id : number] : TimeSlot }))
        : [];
}

function useOrders() {
  const {
    lineItems,
    orders,
    loaded : lineItemsLoaded,
    load : loadOrders,
    createLineItem,
    refreshLineItems,
    refreshLineItem,
    retrieveLineItems,
    retrieveLineItem,
    updateLineItem,
    deleteLineItem,
    bulkCreateLineItems,
    bulkUpdateLineItems,
    claimGuestItems,
    bulkUpdateFulfilments,
    buildOrders : rebuildOrders,
    ...context
  } = useContext(OrderContext);

  const {
    customers,
    loaded : customerLoaded,
    load : loadCustomers,
  } = useCustomers();
  const {
    serviceChannels,
    services,
    locations,
    loaded : servicesLoaded,
    load : loadServices,
  } = useServices();
  const {
    schedules,
    loaded : schedulesLoaded,
    load : loadSchedules,
    findNextIteration,
    calculateTime,
  } = useScheduling();
  const {
    routes,
    loaded : routesLoaded,
    load : loadRoutes,
    retrieveAddressRoutesSync : retrieveAddressRoutes,
  } = useRoutes();
  const {
    products,
    loaded : productsLoaded,
    load : loadProducts,
  } = useProducts();

  const newLineItem = useCallback(
    async (
      // DEPR: lineItem : ({...}) => LineItem | null
      lineItem : LineItem | {
        lineItem : LineItem,
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
        product? : Product | null,
      },
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
        product? : Product | null,
      },
    ) => {
      const item = isLineItem(lineItem) ? lineItem : lineItem.lineItem;
      const {
        address,
        customer,
        serviceChannel,
        location,
        timeSlot,
      } = isLineItem(lineItem) ? (options ?? {}) : lineItem;

      const product = (isLineItem(lineItem)
        ? options?.product
        : lineItem.product) ?? (products && products[item.productId]);
      if (!product) return null;

      return await createLineItem(item, {
        address,
        customer,
        serviceChannel,
        location,
        timeSlot,
        product,
      });
    },
    [products, createLineItem],
  );

  const amendLineItem = useCallback(
    async (
      // DEPR: lineItem : ({...}) => LineItem | null
      lineItem : LineItem | {
        lineItem : LineItem,
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
        product? : Product | null,
      },
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
        product? : Product | null,
      },
    ) => {
      const item = isLineItem(lineItem) ? lineItem : lineItem.lineItem;
      const {
        address,
        customer,
        serviceChannel,
        location,
        timeSlot,
      } = isLineItem(lineItem) ? (options ?? {}) : lineItem;

      return await updateLineItem(item, {
        address,
        customer,
        serviceChannel,
        location,
        timeSlot,
      });
    },
    [updateLineItem],
  );

  const bulkNewLineItems = useCallback(
    async (
      // DEPR: lineItems : ({...}) => DataIndex<LineItem> | null
      lineItems : LineItem[] | {
        lineItems : LineItem[],
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
      },
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
      },
    ) => {
      const items = Array.isArray(lineItems) ? lineItems : lineItems.lineItems;
      const {
        address,
        customer,
        serviceChannel,
        location,
        timeSlot,
      } = Array.isArray(lineItems) ? (options ?? {}) : lineItems;

      return await bulkCreateLineItems(items, {
        address,
        customer,
        serviceChannel,
        location,
        timeSlot,
      });
    },
    [bulkCreateLineItems],
  );

  const bulkAmendLineItems = useCallback(
    async (
      // DEPR: lineItems : ({...}) => DataIndex<LineItem> | null
      lineItems : LineItem[] | {
        lineItems : LineItem[],
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
      },
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
      },
    ) => {
      const items = Array.isArray(lineItems) ? lineItems : lineItems.lineItems;
      const {
        address,
        customer,
        serviceChannel,
        location,
        timeSlot,
      } = Array.isArray(lineItems) ? (options ?? {}) : lineItems;

      return await bulkUpdateLineItems(items, {
        address,
        customer,
        serviceChannel,
        location,
        timeSlot,
      });
    },
    [bulkUpdateLineItems],
  );

  const updateOrderStatus = useCallback(
    async (order : DraftOrder, status : FulfilmentStatus) => {
      if (!order?.order) return null;

      return await bulkUpdateFulfilments(
        Object.values(order.order.fulfilments).map((fulfilment) => ({
          ...fulfilment,
          status,
        })
      ))
    },
    [bulkUpdateFulfilments],
  );

  const claim = useCallback(async (
    customer : Customer,
    options? : { token? : string },
  ) => {
    const lineItems = await claimGuestItems(customer, options);
    if (!lineItems) return null;
    return listRecords(lineItems);
  }, [claimGuestItems]);

  const buildOrders = useCallback(async (filter : {
    address? : Address | null,
    customer? : Customer | null,
    serviceChannel? : ServiceChannel,
    location? : Location | null,
    timeSlot? : TimeSlot,
    iteration? : number,
    division? : number,
  } = {}) => {
    if (!orders) return [];
    return orders.filter((order) => (
      (filter.address === undefined ||
        order.address?.id === filter.address?.id) &&
      (filter.customer === undefined ||
        order.customer?.id === filter.customer?.id) &&
      (filter.serviceChannel === undefined ||
        order.serviceChannel?.id === filter.serviceChannel?.id) &&
      (filter.location === undefined ||
        order.location?.id === filter.location?.id) &&
      (filter.timeSlot === undefined ||
        order.timeSlot?.id === filter.timeSlot?.id) &&
      (filter.iteration === undefined ||
        order.timeSlotIteration === filter.iteration) &&
      (filter.division === undefined ||
        order.timeSlotDivision === filter.division)
    ));
  }, [orders]);

  const findOrder = useCallback(({
    address,
    customer,
    serviceChannel,
    location,
    timeSlot,
    iteration,
    division,
  } : {
    address : Address | null,
    customer : Customer | null,
    serviceChannel : ServiceChannel | null,
    location : Location | null,
    timeSlot : TimeSlot | null,
    iteration : number,
    division : number,
  }) => {
    if (!orders) return null;
    const filtered = orders.filter((order) => (
      order.address?.id === address?.id &&
      order.customer?.id === customer?.id &&
      order.serviceChannel?.id === serviceChannel?.id &&
      order.location?.id === location?.id &&
      order.timeSlot?.id === timeSlot?.id &&
      order.timeSlotIteration === iteration &&
      order.timeSlotDivision === division
    ));

    if (!filtered || !filtered.length) return null;
    return filtered[0];
  }, [orders]);

  const evaluateOptions = useCallback(({
    address,
    customer,
    serviceChannel,
    service,
    location,
    timeSlot,
  } : {
    address? : Address | null,
    customer? : Customer | null,
    serviceChannel? : ServiceChannel | null,
    service? : Service | null,
    location? : Location | null,
    timeSlot? : TimeSlot | null,
  }, options : { strict? : boolean } = {}) : {
    valid : boolean,
    errors : {
      address : boolean,
      customer : boolean,
      serviceChannel : boolean,
      service : boolean,
      location : boolean,
      timeSlot : boolean,
    },
    addresses : Address[],
    customers : Customer[],
    serviceChannels : ServiceChannel[],
    services : Service[],
    locations : Location[],
    schedules : Schedule[],
    timeSlots : TimeSlot[],
  } => {
    const strict = options?.strict ?? false;
    const errors = {
      address : false,
      customer : false,
      serviceChannel : false,
      service : false,
      location : false,
      timeSlot : false,
    };
    let validAddresses = listRecords(customer?.addresses ?? null)
    let validCustomers = listRecords(customers)
    let validServiceChannels = listRecords(serviceChannels)
    let validServices = listRecords(services)
    let validLocations = listRecords(locations)
    let validSchedules = listRecords(schedules)
    let validTimeSlots : TimeSlot[] = [];
    let allRoutes = listRecords(routes);

    validAddresses = filterAddresses(validAddresses, { customer });
    validServiceChannels = filterServiceChannels(validServiceChannels, {
      service,
      services : validServices,
      requireService : true
    });

    errors.serviceChannel = !!serviceChannel &&
      !validServiceChannels.some((sc) => sc.id === serviceChannel.id)

    validServices = filterServices(validServices, { serviceChannel });

    validLocations = filterLocations(validLocations, {
      service,
      services : validServices,
      allRoutes : allRoutes,
    });

    errors.location = !!location &&
      !validLocations.some((l) => l.id === location.id);

    if (validServices.length) {
      validServices = filterServices(validServices, {
        location,
        locations : validLocations,
        routes : allRoutes,
        allRoutes : allRoutes,
      });
      if (location && !validServices.length) errors.location = true;
    }

    if (location && address) errors.address = true;

    validAddresses = filterAddresses(validAddresses, {
      service,
      services : validServices,
      location,
      getRoutes : (address) => retrieveAddressRoutes(address) ?? [],
    });

    if (validServices.length) {
      validServices = filterServices(validServices, {
        address,
        routes : address
          ? retrieveAddressRoutes(address) ?? []
          : allRoutes,
      });
      if (address && !validServices.length) errors.address = true;
    }

    validSchedules = filterSchedules(validSchedules, {
      service,
      services : validServices,
      timeSlot,
    });
    validTimeSlots = filterTimeSlots(validTimeSlots, {
      schedules : validSchedules,
    });

    errors.timeSlot = !!timeSlot &&
      !validTimeSlots.some((ts) => ts.id === timeSlot.id);

    if (validServices.length) {
      validServices = filterServices(validServices, {
        schedules : validSchedules,
      });
      if (timeSlot && !validServices.length) errors.timeSlot = true;
    }

    if (strict) {
      validLocations = filterLocations(
        validLocations,
        { services : validServices }
      );
      validServiceChannels = filterServiceChannels(
        validServiceChannels,
        { services : validServices },
      );
      validAddresses = filterAddresses(
        validAddresses,
        { services : validServices },
      );
    }

    return {
      valid : (
        !errors.address &&
        !errors.customer &&
        !errors.serviceChannel &&
        !errors.location &&
        !errors.service &&
        !errors.timeSlot
      ),
      errors,
      addresses : validAddresses,
      customers : validCustomers,
      serviceChannels : validServiceChannels,
      services : validServices,
      locations : validLocations,
      schedules : validSchedules,
      timeSlots : validTimeSlots,
    };
  }, [
    customers,
    serviceChannels,
    services,
    locations,
    schedules,
    routes,
    retrieveAddressRoutes,
  ]);

  const findServices = useCallback((order : DraftOrder) => {
    const { services } = evaluateOptions(order);
    return services.filter((service) => {
      const delivery = listRecords(routes).some((route) => (
        service.id && route?.serviceIds?.includes(service.id)
      ));
      return delivery ? !order.location : !!order.location;
    });
  }, [routes, evaluateOptions]);

  const determineService = useCallback((order : DraftOrder) => {
    const services = findServices(order);
    return services.length ? services[0] : null;
  }, [findServices]);

  const createDefaultLineItem = useCallback(
    (order : DraftOrder) : LineItem | null => {
      const product = listRecords(products).find((product) => (
        !listRecords(order.lineItems).some(
          (lineItem) => lineItem.productId === product?.id,
        )
      ));

      if (
        (order.address && !order.address.id) ||
        (order.customer && !order.customer.id) ||
        (order.serviceChannel && !order.serviceChannel.id) ||
        (order.location && !order.location.id) ||
        (order.timeSlot && !order.timeSlot.id) ||
        !product?.id
      ) return null;

      return {
        addressId : order.address?.id ?? null,
        customerId : order.customer?.id ?? null,
        serviceChannelId : order.serviceChannel?.id ?? null,
        locationId : order.location?.id ?? null,
        timeSlotId : order.timeSlot?.id ?? null,
        timeSlotIteration : order.timeSlotIteration,
        timeSlotDivision : order.timeSlotDivision,
        productId : product.id,
        quantity : 1,
        price : null,
      }
    },
    [products],
  );

  const createDefaultOrder = useCallback(
    () : DraftOrder => {
      const {
        customers : validCustomers,
        serviceChannels : validServiceChannels
      } = evaluateOptions({});
      const serviceChannel = validServiceChannels[0];
      const customer = validCustomers.length
        ? (validCustomers.length ? validCustomers[0] : null)
        : { contactInfo : {} }

      const { services } = evaluateOptions({ customer, serviceChannel });
      const service = services.length ? services[0] : null;

      const { locations } = evaluateOptions({
        customer,
        serviceChannel,
        service,
      });
      const location = locations.length ? locations[0] : null;

      const { addresses } = evaluateOptions({
        customer,
        serviceChannel,
        service,
        location,
      });
      const address = addresses.length ? addresses[0] : null;

      const { schedules } = evaluateOptions({
        address,
        customer,
        serviceChannel,
        service,
        location,
      });
      const schedule = schedules.length ? schedules[0] : null;

      const { timeSlots } = evaluateOptions({
        address,
        customer,
        serviceChannel,
        service,
        location,
        timeSlot : schedule?.timeSlots
          ? Object.values(schedule?.timeSlots)[0]
          : undefined,
      });
      const timeSlot = timeSlots[0];

      const iteration = findNextIteration(timeSlot, new Date());

      return {
        address : address ?? null,
        customer,
        serviceChannel : serviceChannel,
        location,
        timeSlot,
        service,
        complete : true,
        timeSlotIteration : iteration ?? 0,
        timeSlotDivision : 0,
        time : calculateTime(timeSlot, iteration ?? 0, 0),
        lineItems : {},
        totals : [],
        errors : [],
      };
    },
    [findNextIteration, calculateTime, evaluateOptions],
  );

  const generateDefaultFulfilment = useCallback(({
    order,
    product,
  } : {
    order : DraftOrder | number | undefined;
    product : Product | number | undefined;
  }) : Fulfilment | null => {
    if (typeof order === 'number') {
      order = orders?.find((o) => o.order?.id === order);
    }
    if (!order?.order?.id) return null;
    if (typeof product === 'number') {
      product = listRecords(products)?.find((p) => p.id === product);
    }
    if (!product?.id) return null;

    return {
      orderId : order.order.id,
      lineItemId : null,
      requestedProductId : product.id,
      requestedQty : 1,
      fulfilledProductId : null,
      fulfilledQty : 0,
      unitPrice : product.price,
      price : product.price,
      status : 'pending',
      appliedAdjustments : {},
      taxes : {},
    };
  }, [orders, products]);

  const generateOrderUrl = useCallback(
    (order : DraftOrder, relative = true) => {
      const generator = relative ? generatePath : generateUrl;

      if (order.order && order.order.id) {
        return generator(`/orders/${order.order.id}/`, {});
      }

      return generator(
        `/orders/`,
        {
          ...(order.address && { address: order.address.id }),
          ...(order.customer && { customer: order.customer.id }),
          ...(order.serviceChannel &&
            { serviceChannel: order.serviceChannel.id }),
          ...(order.location && { location: order.location.id }),
          ...(order.timeSlot &&
            { timeSlot: `${order.timeSlot.id}-${order.timeSlotIteration}` +
              `-${order.timeSlotDivision}` }),
        }
      )
    },
    [],
  );

  const load = useCallback(() => {
    loadOrders();
    loadCustomers();
    loadServices();
    loadSchedules();
    loadRoutes();
    loadProducts();
  }, [
    loadOrders,
    loadCustomers,
    loadServices,
    loadSchedules,
    loadRoutes,
    loadProducts,
  ]);

  return {
    lineItems,
    orders,
    loaded : lineItemsLoaded
      && customerLoaded
      && servicesLoaded
      && schedulesLoaded
      && routesLoaded
      && productsLoaded,
    load,
    refreshLineItems,
    refreshLineItem,
    retrieveLineItems,
    retrieveLineItem,
    createLineItem : newLineItem,
    updateLineItem : amendLineItem,
    deleteLineItem,
    bulkCreateLineItems : bulkNewLineItems,
    bulkUpdateLineItems : bulkAmendLineItems,
    bulkUpdateFulfilments,
    updateOrderStatus,
    buildOrders,
    findOrder,
    evaluateOptions,
    findServices,
    determineService,
    createDefaultLineItem,
    createDefaultOrder,
    generateOrderUrl,
    claimGuestItems : claim,
    draftEmptyOrder,
    generateDefaultFulfilment,
    ...context,
  };
}

export default useOrders;
