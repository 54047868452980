import { useMediaQuery as useMuiMediaQuery } from '@mui/material';
import theme from 'theme.json';

function useMediaQuery() {
  const isPrint = useMuiMediaQuery('print');

  const isTablet = useMuiMediaQuery(`(min-width: ${theme.breakpoints.tablet})`);
  const isDesktop = useMuiMediaQuery(
    `(min-width: ${theme.breakpoints.desktop})`
  );

  return {
    isPrint,
    isTablet,
    isDesktop
  };
}

export default useMediaQuery;
