import React, { useCallback, useMemo } from 'react';

import { Collection } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';
import DatePicker from '#materials/DatePicker';

import { shiftLocalDateTime } from '#utils/date';
import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.collections;

interface CollectionFormProps {
  collection : Collection;
  onSubmit? : () => void;
}

function CollectionForm({
  collection : fallback,
  onSubmit,
} : CollectionFormProps) {
  const { state, dispatch, editing, errors } = useForm<Collection>();

  const to = useMemo(() => {
    const ending = state?.ending || fallback.ending;
    if (!ending) return null;
    return shiftLocalDateTime(ending, -86400000);
  }, [state, fallback]);

  const setMin = useCallback((min : number | null) => {
    if (min === null) return;
    dispatch({ min });
  }, [dispatch]);

  const setMax = useCallback((max : number | null) => {
    if (max === null) return;
    dispatch({ max });
  }, [dispatch]);

  const handleSelectedStartDate = useCallback((date : Date | null) => {
    dispatch({ starting : date });
  }, [dispatch]);

  const handleSelectedEndDate = useCallback((date : Date | null) => {
    if (date == null) dispatch({ ending : date });
    else dispatch({ ending : shiftLocalDateTime(date, 86400000) });
  }, [dispatch]);

  const validateStarting = useCallback((value : Date) => {
    if (value < shiftLocalDateTime(new Date(), -86400000)) return false;
    if (state?.ending && (value > state.ending)) return false;
    return true;
  }, [state]);

  const validateEnding = useCallback((value : Date) => {
    if (value < shiftLocalDateTime(new Date(), -86400000)) return false;
    if (state?.starting && (value < state.starting)) return false;
    return true;
  }, [state]);

  const idPrefix = (fallback && fallback.id)
    ? `collection-${fallback?.id}`
    : 'collection-new';

  return (
    <Form onSubmit={onSubmit}>
      <DatePicker
        label={localize(localKeys.labels.startDate)}
        value={state ? state.starting : fallback.starting}
        setValue={handleSelectedStartDate}
        allowClear={false}
        round
        disabled={!editing}
        width={settings.dimensions.quarter}
        validate={validateStarting}
      />
      <DatePicker
        label={localize(localKeys.labels.endDate)}
        value={to}
        setValue={handleSelectedEndDate}
        round
        allowClear
        disabled={!editing}
        width={settings.dimensions.quarter}
        errors={errors.ending}
        validate={validateEnding}
      />
      <TextInput
        id={`${idPrefix}-min`}
        label={localize(localKeys.labels.min)}
        value={state ? state.min : fallback.min}
        onChange={setMin}
        inputType={settings.inputType.number}
        inputFormat={settings.inputFormat.int}
        disabled={!editing}
        width={settings.dimensions.quarter}
      />
      <TextInput
        id={`${idPrefix}-max`}
        label={localize(localKeys.labels.max)}
        value={state ? state.max : fallback.max}
        onChange={setMax}
        inputType={settings.inputType.number}
        inputFormat={settings.inputFormat.int}
        disabled={!editing}
        width={settings.dimensions.quarter}
        errors={errors.max}
      />
    </Form>
  );
}

export default CollectionForm;
