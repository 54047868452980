import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { TimeSlot } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import Form from '#materials/Form';
import TextInput from '#materials/TextInput';
import DateTimePicker from '#materials/DateTimePicker';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.timeSlots;

function roundTime(date : Date) {
  const newDate = new Date(date);
  newDate.setSeconds(0, 0);
  return newDate;
}

interface TimeSlotFormProps {
  timeSlot : TimeSlot;
  enableStart? : boolean;
}

function TimeSlotForm({
  timeSlot: fallback,
  enableStart: editTime = true,
} : TimeSlotFormProps) {
  const { state, dispatch, editing } = useForm<TimeSlot>();

  const [roundedTime, setRoundedTime] = useState<Date>(
    roundTime(fallback.start),
  );

  const setName = useCallback((name : string) => {
    dispatch({ name })
  }, [dispatch]);

  const setStart = useCallback((newDateTime: Date | null) => {
    if (newDateTime === null) return;
    dispatch({ start: roundTime(newDateTime) });
  }, [dispatch]);

  const setEnd = useCallback((newDateTime: Date | null) => {
    if (newDateTime === null) {
      dispatch({ duration : 0 });
      return;
    }
    if (!state) return;

    const duration = newDateTime.getTime() - state?.start.getTime();
    dispatch({ duration });
  }, [state, dispatch]);

  const setDivision = useCallback((division : number | null) => {
    dispatch({ division : division ?? null })
  }, [dispatch]);

  const handleDuration = useCallback((duration : number) => {
    dispatch({ duration });
  }, [dispatch]);

  useEffect(() => {
    setRoundedTime(roundTime(fallback.start));
  }, [fallback]);

  const end = useMemo(() => {
    if (!state || !state.start) return null;
    return new Date(state.start.getTime() + (state.duration));
  }, [state]);

  const idPrefix = (fallback && fallback.id)
    ? `timeSlot-${fallback?.id}`
    : `timeSlot-new`

  return (
    <>
      <Form>
        <TextInput
          id={`${idPrefix}-name`}
          label={localize(localKeys.labels.name)}
          value={state?.name ?? fallback.name}
          onChange={setName}
          disabled={!editing}
          width={settings.dimensions.half}
        />
      </Form>
      <Form>
        <DateTimePicker
          dateLabel={localize(localKeys.labels.startDateTime)}
          timeLabel=""
          value={roundedTime}
          setValue={setStart}
          disabled={!editTime || !editing}
          width={settings.dimensions.half}
        />
        <DateTimePicker
          dateLabel={localize(localKeys.labels.endDateTime)}
          timeLabel=""
          value={end}
          setValue={setEnd}
          disabled={!editTime || !editing}
          width={settings.dimensions.half}
        />
        <TextInput
          id={`${idPrefix}-duration`}
          label={localize(localKeys.labels.duration)}
          value={state?.duration ?? fallback.duration}
          onChange={handleDuration}
          disabled={!editTime || !editing}
          width={settings.dimensions.quarter}
          inputType={settings.inputType.time}
        />
        <TextInput
          id={`${idPrefix}-divisions`}
          label={localize(localKeys.labels.divisions)}
          value={state?.division ?? fallback.division ?? 0}
          onChange={setDivision}
          disabled={!editTime || !editing}
          width={settings.dimensions.quarter}
          inputType={settings.inputType.time}
        />
      </Form>
    </>
  );
}

export default TimeSlotForm;
