import React, { useCallback } from 'react';

import Box from '@mui/material/Box/Box';

import { Dimension, settings } from '#materials';
import Flex from '#materials/Flex';
import FlexItem from '#materials/FlexItem';
import DatePicker from '#materials/DatePicker';
import TimePicker from '#materials/TimePicker';

interface DateTimePickerProps {
  dateLabel : string;
  timeLabel : string;
  value : Date | null;
  defaultValue? : Date;
  setValue? : (value : Date | null) => void;
  validateDate? : (date : Date) => boolean;
  validateTime? : (time : Date) => boolean;
  allowClear? : boolean;
  errors? : string[];
  hideErrors? : boolean;
  disabled? : boolean;
  disableTime? : boolean;
  width? : Dimension;
}
interface DateTimePickerBaseProps extends DateTimePickerProps{
  minuteStep? : number;
}
interface DateTimePickerSelectProps extends DateTimePickerBaseProps{
  minuteStep : number;
}

function DateTimePicker(props : DateTimePickerProps) : JSX.Element;
function DateTimePicker(props : DateTimePickerSelectProps) : JSX.Element;
function DateTimePicker({
  dateLabel,
  timeLabel,
  value,
  setValue,
  defaultValue,
  disabled = false,
  disableTime = false,
  minuteStep,
  validateDate,
  validateTime,
  allowClear = false,
  errors,
  hideErrors = false,
  width = settings.dimensions.full,
} : DateTimePickerBaseProps) {
  const handleClear = useCallback(() => {
    setValue?.(defaultValue ? defaultValue : null);
  }, [defaultValue, setValue]);

  const validate = useCallback((date : Date) => {
    if (validateDate && !validateDate(date)) return false;
    if (validateTime && !validateTime(date)) return false;
    return true;
  }, [validateDate, validateTime]);

  return (
    <FlexItem
      width={settings.dimensions.full}
      maxWidth={width}
    >
      <Box>
        <Flex alignment={settings.alignments.start}>
          <DatePicker
            label={dateLabel}
            value={value}
            setValue={setValue}
            validate={validateDate}
            onClear={allowClear ? handleClear : undefined}
            allowClear={allowClear}
            errors={errors}
            hideErrors={hideErrors}
            disabled={disabled}
          />
          <TimePicker
            label={timeLabel}
            value={value}
            setValue={setValue}
            validate={validate}
            allowClear={allowClear}
            errors={errors}
            hideErrors={hideErrors}
            disabled={disabled || disableTime}
            minuteStep={minuteStep}
          />
        </Flex>
      </Box>
    </FlexItem>
  )
}

export default DateTimePicker;
