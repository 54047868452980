import React, { useCallback, useEffect, useState } from 'react';

import { Customer, isCustomer, isContactInfo, isCustomerUser } from '#types';

import { FormProvider } from '#context/FormContext';

import useNotifications from '#hooks/useNotifications';
import useForm from '#hooks/useForm';
import useCustomers from '#hooks/useCustomers';

import Section from '#components/dashboard/Section';
import CustomerForm, { CustomerInfo } from '#components/customers/CustomerForm';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.customers.createCustomer;
const localeFormKeys = locale.keys.forms.customer
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.customers;

function validateCustomerInfo(customer : CustomerInfo) {
  if (customer.name && customer.email) return null;
  return {
    ...(!customer.name &&
      { name : localize(localeFormKeys.errors.nameRequired) }),
    ...(!customer.email &&
      { email : localize(localeFormKeys.errors.emailRequired) }),
  };
}

interface CreateCustomerProps {
  onSave? : (customer : Customer) => void;
  onCancel? : () => void;
}

function CreateCustomerControl({ onCancel, onSave } : CreateCustomerProps) {
  const { createNotification } = useNotifications();
  const { state, valid, reset } = useForm<CustomerInfo>();
  const { createCustomer } = useCustomers();

  const [cleared, setCleared] = useState(true);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback(async () => {
    if (!state) return;
    setSaving(true);

    const customer = { contactInfo : {} };
    const contactInfo = {
      name : state.name,
      nickname : state.nickname,
      email : state.email,
      phone : state.phone,
      smsNotifications : false,
      emailNotifications : false,
    };
    const user = { username : state.email };
    if (
      !isCustomer(customer) ||
      !isContactInfo(contactInfo) ||
      !isCustomerUser(user)
    ) {
      createNotification({
        key : 'create-customer-error',
        message : localize(localeNotificationKeys.create.invalid),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.alert,
      });
      return;
    }

    const { customer : newCustomer, taken } = await createCustomer(
      customer,
      { contactInfo, user }
    );
    setSaving(false);

    if (taken) {
      createNotification({
        key : 'create-customer-conflict',
        message : localize(localeNotificationKeys.create.conflict),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.alert,
      });
      return;
    }

    if (!!newCustomer) {
      createNotification({
        key : 'create-customer-success',
        message : localize(localeNotificationKeys.create.success),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.primary,
      });
      if (onSave) onSave(newCustomer);
    } else {
      createNotification({
        key : 'create-customer-error',
        message : localize(localeNotificationKeys.create.error),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [state, createCustomer, onSave, createNotification]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const handleClear = useCallback(() => {
    reset();
    setCleared(true);
  }, [reset, setCleared]);

  useEffect(() => {
    setCleared(false);
  }, [state, setCleared] );

  return (
    <Section title={localize(localeContentKeys.title)}>
      <CustomerForm />
      <Button
        onClick={handleSave}
        disabled={!valid || saving}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
      <Button
        onClick={handleClear}
        disabled={cleared}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
    </Section>
  );
}

function CreateCustomer(props : CreateCustomerProps) {
  return (
    <FormProvider<CustomerInfo>
      init={{
        name : '',
        nickname : '',
        email : '',
        phone : '',
      }}
      validators={[validateCustomerInfo]}
    >
      <CreateCustomerControl {...props} />
    </FormProvider>
  );
}

export default CreateCustomer;
