import { useCallback, useEffect, useState } from 'react';

import { Adjustment } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import Form from '#materials/Form';
import TextInput from '#materials/TextInput';
import Switch from '#materials/Switch';

import locale, { localize } from '#utils/locale';

const localeKeys = locale.keys.forms.adjustments;

interface AdjustmentFormProps {
  adjustment : Adjustment;
}

const AdjustmentForm = ({
  adjustment: fallback,
} : AdjustmentFormProps) => {
  const { state, dispatch, editing } = useForm<Adjustment>();
  const [isChecked, setIsChecked] = useState(false);

  const handleSetScope = useCallback((switchState : boolean) => {
    dispatch({ scope : switchState ? 'order' : 'lineItem' });
  }, [dispatch])

  const handleSetAdjName = useCallback((name : string) => {
    dispatch({ name });
  }, [dispatch]);

  const handleSetCurrency = useCallback((amount : number | null) => {
    dispatch({ currency : {
      amount : ((amount ?? 0) / (state?.currency?.increment ?? 0.01)),
      currencyCode : 'CAD',
      increment : 0.01,
      calculatedValue : amount ?? 0,
    } });
  }, [dispatch, state?.currency?.increment]);

  const handleSetFactor = useCallback((factor : number | null) => {
    dispatch({ factor : factor ? factor / 100 : 0 });
  }, [dispatch]);

  useEffect(() => {
    handleSetScope(isChecked);
  }, [isChecked, handleSetScope]);

  const amount = state?.currency?.calculatedValue
    ?? fallback.currency?.calculatedValue
    ?? 0;

  return (
    <Form>
      <TextInput
        id={`new-adjustment-name`}
        label={localize(localeKeys.labels.name)}
        value={state ? state.name : fallback.name}
        onChange={handleSetAdjName}
        disabled={!editing}
        width={settings.dimensions.twoThirds}
      />
      <Switch
        label={localize(localeKeys.labels.adjustTotal)}
        checked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
        disabled={!editing}
        width={settings.dimensions.third}
      />
      <TextInput
        id={'adjustment-currency'}
        value={amount}
        label={localize(localeKeys.labels.currency)}
        inputType={settings.inputType.number}
        inputFormat={settings.inputFormat.currency}
        onChange={handleSetCurrency}
        disabled={state?.factor !== 0}
        width={settings.dimensions.half}
      />
      <TextInput
        id={'adjustment-factor'}
        value={(state?.factor ?? fallback.factor ?? 1) * 100}
        label={localize(localeKeys.labels.factor)}
        onChange={handleSetFactor}
        inputType={settings.inputType.number}
        inputFormat={settings.inputFormat.int}
        disabled={!!amount}
        width={settings.dimensions.half}
      />
    </Form>
  );
}

export default AdjustmentForm;
