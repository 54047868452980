import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { TimeSlot, Window } from '#types';

import { FormProvider } from '#context/FormContext';

import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useTimeSlots from '#hooks/useTimeSlots';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Form from '#materials/Form';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import DateTimePicker from '#materials/DateTimePicker';

import WindowForm from '#components/timeslots/WindowForm';

import { roundDateTime, timeScales } from '#utils/date';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.timeSlots.timeSlotWindows;
const localeNotificationKeys = locale.keys.notifications.timeSlots;
const localeFormKeys = locale.keys.forms.windows;
const localeButtonKeys = locale.keys.buttons;

interface WindowDetailsProps {
  timeSlot : TimeSlot;
}
interface WindowDetailsControlProps extends WindowDetailsProps {
  window : Window;
}

function WindowDetailsControl({
  window : init,
  timeSlot,
} : WindowDetailsControlProps) {
  const { createNotification } = useNotifications();
  const {
    refreshTimeSlot,
    createWindow,
    findNextIteration,
    calculateDate,
    calculateMinStep,
    calculateDuration,
    validateTimeSlotDay,
    validateTimeSlotDateTime,
  } = useTimeSlots();

  const {
    state : window,
    editing,
    valid,
    dispatch,
    reset,
  } = useFormContext<Window>();

  const [startTime, setStartTime] = useState<Date | null>(null);

  const handleCancel = useCallback(() => {
    setStartTime(null);
    reset();
  }, [reset]);

  const handleSave = useCallback(async () => {
    if (!window) return;
    const success = !!(await createWindow(window));

    if (success) {
      if (timeSlot.id) await refreshTimeSlot(timeSlot.id);
      createNotification({
        key : 'create-window-success',
        message : localize(localeNotificationKeys.windowCreated.success),
        icon : (<Icon icon={settings.svgIcons.accessTime} />),
        colour : settings.colours.alert.success,
      });
      setStartTime(null);
      reset();
    } else {
      createNotification({
        key : 'create-window-failure',
        message : localize(localeNotificationKeys.windowCreated.error),
        icon : (<Icon icon={settings.svgIcons.accessTime} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [
    window,
    timeSlot,
    reset,
    refreshTimeSlot,
    createWindow,
    createNotification,
  ]);

  const handleStartDate = useCallback((value : Date | null) => {
    if (!value) {
      setStartTime(null);
      return;
    }

    const i = findNextIteration(timeSlot, roundDateTime(value, timeScales.day));
    if (i === undefined) return;
    setStartTime(calculateDate(timeSlot, i));
  }, [timeSlot, findNextIteration, calculateDate]);

  const validateSelectedStartDate = useCallback((date : Date) => {
    return (date.getTime() >= new Date().getTime() &&
      validateTimeSlotDay(timeSlot, date));
  }, [timeSlot, validateTimeSlotDay]);

  const validateSelectedStartTime = useCallback((date : Date) => {
    return validateTimeSlotDateTime(timeSlot, date);
  }, [timeSlot, validateTimeSlotDateTime]);

  const endTime = useMemo(() => {
    if (!startTime) return null;
    const i = findNextIteration(timeSlot, startTime);
    if (i === undefined) return null;
    return new Date(startTime.getTime() + calculateDuration(timeSlot, i));
  }, [timeSlot, startTime, findNextIteration, calculateDuration]);

  const minStep = useMemo(() => (
    calculateMinStep(timeSlot)
  ), [timeSlot, calculateMinStep]);

  useEffect(() => {
    if (!startTime) return;

    const i = findNextIteration(timeSlot, startTime);
    if (i === undefined) return;
    dispatch({
      start : startTime,
      duration : endTime ? (endTime.getTime() - startTime.getTime()) : 0,
      fromIteration : i,
      toIteration : null,
    });
  }, [timeSlot, startTime, endTime, findNextIteration, dispatch, reset]);

  if (minStep < 6e4) return (<></>);

  return (
    <>
      <Form>
        <DateTimePicker
          dateLabel={localize(localeFormKeys.labels.selectDateTime)}
          timeLabel=""
          value={startTime}
          setValue={handleStartDate}
          allowClear
          minuteStep={minStep / 6e4}
          validateDate={validateSelectedStartDate}
          validateTime={validateSelectedStartTime}
          hideErrors
          disabled={!editing}
          disableTime
          width={settings.dimensions.half}
        />
        { startTime && (
          <DateTimePicker
            dateLabel={localize(localeFormKeys.labels.selectDateTimeEnd)}
            timeLabel=""
            value={endTime}
            disabled
            width={settings.dimensions.half}
          />
        ) }
      </Form>
      { startTime && (
        <>
          <Segment title={localize(localeContentKeys.reschedule)} />
          <WindowForm window={init} timeSlot={timeSlot} />
          <Button
            onClick={handleSave}
            disabled={!window || !valid}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
      ) }
    </>
  );
}

function WindowDetails({ timeSlot } : WindowDetailsProps) {
  const {
    findNextIteration,
    calculateDate,
    calculateDuration,
  } = useTimeSlots();

  const now = new Date();
  const nextiteration = findNextIteration(timeSlot, now) ?? 0;
  const start = calculateDate(timeSlot, nextiteration);
  const duration = calculateDuration(timeSlot, nextiteration);
  const [defaultWindow] = useState<Window>({
    start : start,
    fromIteration : nextiteration,
    toIteration : null,
    timeSlotId : timeSlot.id ?? -1,
    duration : duration,
    division : timeSlot.division,
  });

  return (
    <FormProvider init={defaultWindow} editing={true}>
      <WindowDetailsControl window={defaultWindow} timeSlot={timeSlot} />
    </FormProvider>
  );
}

export default WindowDetails;
