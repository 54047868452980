import React from 'react';

import { DraftCustomOrder } from '#types';

import useNotes from '#hooks/useNotes';

import Section from '#components/dashboard/Section';
import ItemList from '#components/lineItems/ItemList';

import TextInput from '#materials/TextInput';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.packingList;
const localeFormKeys = locale.keys.forms.orders;

interface PackingListProps {
  order : DraftCustomOrder;
}

function PackingList({ order } : PackingListProps) {
  const { getCustomerNotes, getAddressNotes, getOrderNotes } = useNotes();

  const customerNote = order.customer
    ? getCustomerNotes(order.customer).map((n) => n.content).join('\n\n')
    : '';
  const addressNote = order.address
    ? getAddressNotes(order.address).map((n) => n.content).join('\n\n')
    : '';
  const orderNote = order.order
    ? getOrderNotes(order.order).map((n) => n.content).join('\n\n')
    : '';

  return (
    <Section
      title={order.order?.id
        ? `${localize(localeContentKeys.title)} - `
          + `${localize(localeContentKeys.order)} # ${order.order.id}`
        : localize(localeContentKeys.title)}
    >
      <ItemList
        order={order}
        showFulfilmment
        mergeProducts
        mergePricing
      />
      { orderNote && (
        <TextInput
          id="order-note"
          label={localize(localeFormKeys.labels.orderNote)}
          value={orderNote}
          minRows={1}
          disabled
        />
      ) }
      { addressNote && (
        <TextInput
          id="address-note"
          label={localize(localeFormKeys.labels.addressNote)}
          value={addressNote}
          minRows={1}
          disabled
        />
      ) }
      { customerNote && (
        <TextInput
          id="customer-note"
          label={localize(localeFormKeys.labels.customerNote)}
          value={customerNote}
          minRows={1}
          disabled
        />
      ) }
    </Section>
  );
}

export default PackingList;
