import React, { useCallback, useEffect, useState } from 'react';

import { ProjectedOrder } from '#types';

import useCustomers from '#hooks/useCustomers';
import useServices from '#hooks/useServices';
import useScheduling from '#hooks/useTimeSlots';
import useSubscriptions from '#hooks/useSubscriptions';

import Section from '#components/dashboard/Section';
import OrderDetails from '#components/orders/OrderDetails';
import OrderNotes from '#components/orders/OrderNotes';
import OrderIntegrations from '#components/orders/OrderIntegrations';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.orderInfo;

interface OrderInfoProps {
  orderId? : number;
  addressId? : number | null;
  customerId? : number;
  serviceChannelId? : number;
  locationId? : number | null;
  timeSlotId? : number;
  iteration? : number;
  division? : number;
}

function OrderInfo({
  orderId,
  addressId,
  customerId,
  serviceChannelId,
  locationId,
  timeSlotId,
  iteration,
  division,
} : OrderInfoProps) {
  const { retrieveCustomer } = useCustomers();
  const { retrieveServiceChannel, retrieveLocation } = useServices();
  const { retrieveTimeSlot } = useScheduling();
  const { projectedOrders, findProjectedOrder } = useSubscriptions();

  const [order, setOrder] = useState<ProjectedOrder | null>(null);

  const retrieve = useCallback(async () => {
    if (orderId) {
      const foundOrder = projectedOrders.find((order) => (
        order.order?.id === orderId
      ));
      setOrder(foundOrder ?? null);
      return;
    }

    if (
      !customerId
        || !serviceChannelId
        || !timeSlotId
        || locationId === undefined
        || addressId === undefined
        || iteration === undefined
        || division === undefined
    ) return;

    const [
      customer,
      serviceChannel,
      location,
      timeSlot,
    ] = await Promise.all([
      retrieveCustomer(customerId),
      retrieveServiceChannel(serviceChannelId),
      locationId ? retrieveLocation(locationId) : null,
      retrieveTimeSlot(timeSlotId),
    ]);

    const address = (customer?.addresses && addressId)
      ? (customer.addresses[addressId] ?? null)
      : null

    if (!customer || !serviceChannel || !timeSlot) {
      setOrder(null);
      return;
    }

    const builtOrder = await findProjectedOrder({
      address,
      customer,
      serviceChannel,
      location,
      timeSlot,
      iteration,
      division,
    });
    setOrder(builtOrder);
  }, [
    orderId,
    addressId,
    customerId,
    serviceChannelId,
    locationId,
    timeSlotId,
    iteration,
    division,
    retrieveCustomer,
    retrieveServiceChannel,
    retrieveLocation,
    retrieveTimeSlot,
    projectedOrders,
    findProjectedOrder,
  ]);

  useEffect(() => { retrieve(); }, [retrieve]);

  return (
    !!order
      ? <>
        <OrderDetails order={order} />
        <OrderNotes order={order} />
        <OrderIntegrations order={order} />
      </>
      : ((order === null) ?
        ( <Section title={localize(localeContentKeys.notFound)} />)
        : <></>)
  );
}

export default OrderInfo;
