import React, { useEffect } from 'react';

import { DraftOrder } from '#types';

import useNotes from '#hooks/useNotes';

import Section from '#components/dashboard/Section';

import TextInput from '#materials/TextInput';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.orderNotes;
const localeFormKeys = locale.keys.forms.orders;

interface OrderNotesProps {
  order : DraftOrder;
}

function OrderNotes({ order } : OrderNotesProps) {
  const {
    loadNotes,
    getCustomerNotes,
    getAddressNotes,
    getOrderNotes,
  } = useNotes();

  const customerNote = order.customer
    ? getCustomerNotes(order.customer).map((n) => n.content).join('\n\n')
    : '';
  const addressNote = order.address
    ? getAddressNotes(order.address).map((n) => n.content).join('\n\n')
    : '';
  const orderNote = order.order
    ? getOrderNotes(order.order).map((n) => n.content).join('\n\n')
    : '';

  useEffect(() => { loadNotes(); }, [loadNotes]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      { orderNote && (
        <TextInput
          id="order-note"
          label={localize(localeFormKeys.labels.orderNote)}
          value={orderNote}
          minRows={1}
          disabled
        />
      ) }
      { addressNote && (
        <TextInput
          id="address-note"
          label={localize(localeFormKeys.labels.addressNote)}
          value={addressNote}
          minRows={1}
          disabled
        />
      ) }
      { customerNote && (
        <TextInput
          id="customer-note"
          label={localize(localeFormKeys.labels.customerNote)}
          value={customerNote}
          minRows={1}
          disabled
        />
      ) }
    </Section>
  );
}

export default OrderNotes;
