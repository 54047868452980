import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';

import { convert, settings } from '#materials';

interface TabObject {
  tabName : string;
  tabContent : React.ReactNode;
  tabIcon? : React.ReactElement;
}

interface TabsProps {
  tabObjects : TabObject[];
  tab? : string;
  setTab? : (tab : string) => void;
}

function a11yProps(index : number, tabName : string) {
  return {
    id: `${tabName}-${index}`,
    'aria-controls': `${tabName}-${index}`,
  };
}

const Tabs = ({
  tabObjects,
  tab,
  setTab,
} : TabsProps) => {
  const [value, setValue] = useState(0);

  const handleChangeTab = useCallback(
    (event : SyntheticEvent, newValue : number) => {
    setValue(newValue);
    setTab?.(tabObjects[newValue].tabName ?? '');
  }, [tabObjects, setTab]);

  useEffect(() => {
    if (!tab) return;
    const index = tabObjects.findIndex(
      (tabObject) => tabObject.tabName === tab
    );
    if (index === -1) return;
    setValue(index);
  }, [tab, tabObjects]);

  const index = tab
    ? tabObjects.findIndex((tabObject) => tabObject.tabName === tab)
    : value;

  const margin = {
    x : settings.spacings.dense,
    top : settings.spacings.medium,
    bottom : settings.spacings.small,
  }

  return (
    <>
      <MuiTabs
        value={index}
        onChange={handleChangeTab}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          ...(margin && {
            mt : '-' + convert.margin(margin).top,
            mr : convert.margin(margin).right,
            mb : convert.margin(margin).bottom,
            ml : convert.margin(margin).left,
          }),
        }}
      >
        {
          tabObjects.map((tabObject, index) => (
            <MuiTab
              icon={tabObject.tabIcon}
              key={index}
              label={tabObject.tabIcon ? null : tabObject.tabName}
              {...a11yProps(index, tabObject.tabName)}
            />
          ))
        }
      </MuiTabs>
      <Box>
        {
          tabObjects.map((tabObject, index) => (
            <Box key={index} hidden={value !== index}>
              { tabObject.tabContent }
            </Box>
          ))
        }
      </Box>
    </>
  );
}

export default Tabs;
