import React, { useCallback, useEffect, useState } from 'react';

import {
  Address,
  Customer,
  Product,
  ServiceChannel,
  Location,
  Schedule,
  ScheduleSelection,
  LineItem,
  Fulfilment,
  ProjectedOrder,
  Selection,
} from '#types';

import useMediaQuery from '#hooks/useMediaQuery';
import useNotifications from '#hooks/useNotifications';
import useForm from '#hooks/useForm';
import useProducts from '#hooks/useProducts';
import useServices from '#hooks/useServices';
import useScheduling from '#hooks/useTimeSlots';
import useOrders from '#hooks/useOrders';
import useOptions from '#hooks/useOptions';
import useSubscriptions from '#hooks/useSubscriptions';

import { settings } from '#materials';
import Form from '#materials/Form';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Select from '#materials/Select';
import Banner from '#materials/Banner';
import {
  Action,
  CellElement,
  TableCell,
  TableActionCell,
} from '#materials/TableCell';

import TimeSlotPicker from '#components/timeslots/TimeSlotPicker';
import LineItemTable from '#components/lineItems/LineItemTable';
import LineItemRow, {
  TABLE_KEYS,
  TableKey,
  defaultTableKeys,
} from '#components/lineItems/LineItemRow';
import LineItemSelectionsRow, {
  TableKey as SelectionTableKey,
  SELECTION_TABLE_KEYS,
  defaultSelectionTableKeys,
} from '#components/lineItems/LineItemSelectionsRow';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

export {
  TABLE_KEYS,
  SELECTION_TABLE_KEYS,
  defaultTableKeys,
  defaultSelectionTableKeys,
};

const localeContentKeys = locale.keys.content.orders.orderForm;
const localeFormKeys = locale.keys.forms.orders;
const localeTableKeys = locale.keys.tables.lineItems;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.lineItems;

export type OrderFormMode = 'create' | 'fulfilment' | 'edit';

interface OrderFormProps {
  order : ProjectedOrder;
  mode? : OrderFormMode;
  active? : boolean;
  freeEdit? : boolean;
  showNotes? : boolean;
  disableCustomer? : boolean;
  disableProduct? : boolean;
  disableActions? : boolean;
  disabled? : boolean;
  itemsReady? : boolean;
  setItemsReady? : (editing : boolean) => void;
  onAddLineItem? : (
    lineItem : LineItem,
    selections? : Selection[],
    subscriptionOptions? : { period? : number },
  ) => void | boolean | Promise<boolean | null | void>;
  onUpdateLineItem? : (
    lineItem : LineItem,
    selections? : Selection[],
    subscriptionOptions? : {
      startIteration? : number,
      endIteration? : number | null,
      targetIteration? : number,
      period? : number
    },
  ) => void | boolean | Promise<boolean | null | void>;
  onRemoveLineItem? : (
    lineItem : LineItem,
    subscriptionOptions? : {
      startIteration : number,
      endIteration? : number | null,
    },
  ) => void | boolean | Promise<boolean | null | void>;
  onDeleteFulfilment? : (fulfilment : Fulfilment) => () => void | Promise<void>;
  onUpdateFulfilment? : (fulfilment : Fulfilment) => void | Promise<void>;
  setEditingItem? : (edit : boolean) => void;
  tableKeys? : TableKey[];
  selectionTableKeys? : SelectionTableKey[];
}

function OrderForm({
  order : initialOrder,
  mode,
  freeEdit = false,
  disableActions,
  disableCustomer = false,
  disableProduct = false,
  disabled = false,
  tableKeys = defaultTableKeys,
  showNotes = false,
  selectionTableKeys = defaultSelectionTableKeys,
  setItemsReady,
  onAddLineItem,
  onUpdateLineItem,
  onRemoveLineItem,
  onUpdateFulfilment,
  onDeleteFulfilment,
  setEditingItem,
  active = true,
} : OrderFormProps) {
  const { isDesktop } = useMediaQuery();
  const { createNotification } = useNotifications();
  const {
    state : order,
    dispatch,
    errors,
    editing,
  } = useForm<ProjectedOrder>();
  const { products } = useProducts();
  const { retrieveServiceChannels } = useServices();
  const { calculateTime } = useScheduling();
  const { evaluateOptions, createDefaultLineItem } = useOrders();
  const {
    isProductCustomisable,
    getProductAssemblies,
    getAssemblyProducts,
  } = useOptions();
  const { isLineItemRecurring, determineTrueIteration } = useSubscriptions();

  const [addresses, setAddresses] = useState<Address[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [serviceChannels, setServiceChannels] = useState<ServiceChannel[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [lineItems, setLineItems] = useState<LineItem[]>([]);
  const [selections, setSelections] = useState<Selection[]>([]);

  // TODO: ensure newLineItem is not stale, sync with downstream form changes
  const [newProduct, setNewProduct] = useState<Product | null>(null);
  const [newLineItem, setNewLineItem] = useState<LineItem | null>(null);
  const [newSelections, setNewSelections] = useState<Selection[]>([]);
  const [editingItems, setEditingItems] = useState<number[]>([]);
  const [removeTarget, setRemoveTarget] = useState<LineItem | null>(null);
  const [subscriptTarget, setSubscriptTarget] = useState<{
    lineItem : LineItem,
    selections? : Selection[],
    options? : { period? : number, endIteration? : number | null },
    deleting? : boolean,
  } | null>(null);
  const [extraRow, setExtraRow] = useState<CellElement | CellElement[]>();

  const busy = !!removeTarget || !!subscriptTarget;

  const retrieve = useCallback(async () => {
    const allServiceChannels = await retrieveServiceChannels();
    if (!allServiceChannels) return;
    setServiceChannels(listRecords(allServiceChannels));
  }, [retrieveServiceChannels]);

  const handleCustomer = useCallback((customer : Customer | null) => {
    dispatch({ customer });
  }, [dispatch]);

  const handleAddress = useCallback((address : Address | null) => {
    dispatch({ address });
  }, [dispatch]);

  const handleServiceChannel = useCallback(
    (serviceChannel : ServiceChannel | null) => {
      dispatch({ serviceChannel });
    },
    [dispatch],
  );

  const handleLocation = useCallback((location : Location | null) => {
    dispatch({ location });
  }, [dispatch]);

  const handleOrderTime = useCallback(
    (selections : ScheduleSelection[]) => {
      const s = selections[0];
      dispatch({
        timeSlot : s?.timeSlot,
        time : s ? calculateTime(s.timeSlot, s.iteration, s.division) : null,
        timeSlotIteration : s?.iteration ?? 0,
        timeSlotDivision : s?.division ?? 0,
      });
    },
    [dispatch, calculateTime],
  );

  const handleAddLineItem = useCallback(() => {
    const lineItem = createDefaultLineItem(initialOrder);

    if (!lineItem) {
      createNotification({
        key : 'create-line-item-error',
        message : localize(localeNotificationKeys.create.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      })
    }
    setNewLineItem(lineItem);
  }, [initialOrder, setNewLineItem, createDefaultLineItem, createNotification]);

  const handleSaveNewLineItem = useCallback(async (
    lineItem : LineItem,
    subscriptionOptions? : {period? : number},
  ) => {
    if ((await onAddLineItem?.(
      lineItem,
      (newSelections.map(s => ({ ...s, id : undefined }))),
      { period : subscriptionOptions?.period }
    )) === false) return;
    setNewLineItem(null);
    setNewSelections([]);
  }, [newSelections, onAddLineItem, setNewLineItem]);

  const handleCancelNewLineItem = useCallback(() => {
    setNewLineItem(null);
    setNewSelections([]);
  }, [setNewLineItem]);

  const handleEditLineItem = useCallback((lineItem : LineItem) => () => {
    if (!lineItem.id) return;
    if (editingItems.includes(lineItem.id)) return;
    setEditingItems([...editingItems, lineItem.id]);
  }, [editingItems, setEditingItems]);

  const handleSave = useCallback(async (
    lineItem : LineItem,
    itemSelections? : Selection[],
    options? : { period? : number },
  ) => {
    if (!lineItem.id || !(lineItem?.id > 0) || !isLineItemRecurring(lineItem)) {
      return onUpdateLineItem?.(lineItem, itemSelections, options);
    }

    if (removeTarget) return false;
    setSubscriptTarget({
      lineItem : { ...lineItem },
      selections: itemSelections,
      options
    });
    return false;
  }, [
    onUpdateLineItem,
    removeTarget,
    isLineItemRecurring,
  ]);

  const handleSaveSubscription = useCallback((
    thisOnly : boolean,
  ) => async () => {
    if (!subscriptTarget) return;
    if (!initialOrder.timeSlot) return;

    const start = determineTrueIteration(
      subscriptTarget.lineItem,
      initialOrder.timeSlot,
      initialOrder.timeSlotIteration,
    )

    const success = await onUpdateLineItem?.(
      subscriptTarget.lineItem,
      subscriptTarget.selections,
      {
        startIteration : start,
        endIteration : thisOnly ? start : null,
        targetIteration : initialOrder.timeSlotIteration,
        period : subscriptTarget.options?.period
      }
    );
    if (success !== false) {
      setSubscriptTarget(null);
      setEditingItems(editingItems.filter(
        id => id !== subscriptTarget.lineItem.id
      ));
    }
  }, [
    initialOrder,
    subscriptTarget,
    editingItems,
    determineTrueIteration,
    onUpdateLineItem,
    setSubscriptTarget,
  ]);

  const handleInitRemove = useCallback((
    lineItem : LineItem,
    options? : { endIteration? : number | null },
  ) => () => {
    if (!isLineItemRecurring(lineItem)) {
      setRemoveTarget(lineItem);
      return;
    }

    setSubscriptTarget({
      lineItem,
      options,
      deleting : true,
    });
  }, [isLineItemRecurring, setRemoveTarget, setSubscriptTarget]);

  const handleCancelRemove = useCallback(() => {
    setRemoveTarget(null);
    setSubscriptTarget(null);
  }, [setRemoveTarget]);

  const handleConfirmRemove = useCallback(async () => {
    if (!removeTarget || !onRemoveLineItem) return;
    const success = await onRemoveLineItem(removeTarget);
    if (success) {
      setRemoveTarget(null);
      setEditingItems(editingItems.filter(
        id => id !== removeTarget.id
      ));
    }
  }, [removeTarget, editingItems, onRemoveLineItem, setRemoveTarget]);

  const handleConfirmRemoveSubscription = useCallback(
    (thisOnly : boolean) => async () => {
      if (!subscriptTarget) return;
      if (!initialOrder?.timeSlot) return;

      const start = determineTrueIteration(
        subscriptTarget.lineItem,
        initialOrder.timeSlot,
        initialOrder.timeSlotIteration,
      )

      const success = await onRemoveLineItem?.(
        subscriptTarget.lineItem,
        {
          startIteration : start,
          endIteration : thisOnly ? start : null,
        }
      );
      if (success !== false) {
        setSubscriptTarget(null);
        setEditingItems(editingItems.filter(
          id => id !== subscriptTarget.lineItem.id
        ));
      }
    },
    [
      initialOrder,
      subscriptTarget,
      editingItems,
      determineTrueIteration,
      onRemoveLineItem,
      setSubscriptTarget,
    ],
  );

  const handleNewProductChange = useCallback((product : Product | null) => {
      if (!newLineItem || !product?.id) return;
      setNewProduct(product);
      setNewLineItem({
        ...newLineItem,
        productId : product.id,
      });
    },
    [newLineItem, setNewProduct, setNewLineItem],
  );

  const handleNewSelections = useCallback((selection : Selection) => {
    setNewSelections(newSelections.some(
      s => s.id === selection.id
    ) ? newSelections.map(
      s => s.id === selection.id ? selection : s
    ) : [...newSelections, selection]);
  }, [newSelections, setNewSelections]);

  const handleAddNewSelection = useCallback(() => {
    if (!newProduct) return;

    const assembly = getProductAssemblies(
      newProduct
    )[0];
    if (!assembly?.id) return;
    const products = getAssemblyProducts(
      assembly,
      initialOrder?.time ?? new Date(),
    )
    if (!products[0]?.id) return;
    setNewSelections([
      ...newSelections,
      {
        id : Math.min(
          ...newSelections.map(s => s.id ?? 0), 0
        ) - 1,
        lineItemId : NaN,
        assemblyId : assembly.id,
        productId : products[0].id,
        quantity : 1,
        fulfilmentIds : [],
      },
    ]);
  }, [
    initialOrder,
    newProduct,
    newSelections,
    setNewSelections,
    getProductAssemblies,
    getAssemblyProducts,
  ]);

  const handleRemoveNewSelection = useCallback(
    (selection : Selection) => () => {
      setNewSelections(newSelections.filter(
        s => s.id !== selection.id
      ));
    },
    [newSelections, setNewSelections],
  );

  const generateActions = useCallback((lineItem : LineItem) => {
    return !disabled
      ? (
        <TableActionCell width={settings.dimensions.xsmall}>
          { !!onUpdateLineItem && (
              <Action
                label={localize(localeTableKeys.actions.edit)}
                onClick={handleEditLineItem(lineItem)}
                disabled={(
                  disableActions
                    || (!!removeTarget?.id
                      && !!lineItem?.id
                      && (removeTarget?.id === lineItem.id))
                )}
              >
                <Icon icon={settings.svgIcons.edit} />
              </Action>
          ) }
          { !!onRemoveLineItem && (
              <Action
                label={localize(localeTableKeys.actions.remove)}
                onClick={handleInitRemove(lineItem)}
                disabled={disableActions || busy}
                colour={settings.colours.button.alert}
              >
                <Icon icon={settings.svgIcons.remove} />
              </Action>
          ) }
        </TableActionCell>
      ) : (
        <></>
      );
  }, [
    disabled,
    disableActions,
    onUpdateLineItem,
    onRemoveLineItem,
    busy,
    removeTarget,
    handleEditLineItem,
    handleInitRemove,
  ]);

  const generateConfirmationRow = useCallback((lineItem : LineItem) => {
    if (subscriptTarget && subscriptTarget.lineItem.id === lineItem.id) {
      return subscriptTarget.deleting
        ? (
          <TableCell colSpan={tableKeys.length}>
            <Banner
              icon={(<Icon icon={settings.svgIcons.remove} />)}
              width={settings.dimensions.full}
              height={settings.sizes.small}
              actions={(
                <>
                  <Button onClick={handleConfirmRemoveSubscription(true)}>
                    { localize(localeContentKeys.deleteThis) }
                  </Button>
                  <Button onClick={handleConfirmRemoveSubscription(false)}>
                    { localize(localeContentKeys.deleteFuture) }
                  </Button>
                  <Button onClick={() => setSubscriptTarget(null)}>
                    { localize(localeButtonKeys.cancel) }
                  </Button>
                </>
              )}
              colour={settings.colours.alert.alert}
            >
              { localize(localeContentKeys.confirmRemoveSubscription) }
            </Banner>
          </TableCell>
        ) : (
          <TableCell colSpan={tableKeys.length}>
            <Banner
              icon={(<Icon icon={settings.svgIcons.refresh} />)}
              width={settings.dimensions.full}
              height={settings.sizes.small}
              actions={(
                <>
                  <Button onClick={handleSaveSubscription(true)}>
                    { localize(localeContentKeys.saveThis) }
                  </Button>
                  <Button onClick={handleSaveSubscription(false)}>
                    { localize(localeContentKeys.saveFuture) }
                  </Button>
                  <Button onClick={() => setSubscriptTarget(null)}>
                    { localize(localeButtonKeys.cancel) }
                  </Button>
                </>
              )}
              colour={settings.colours.alert.secondary}
            >
              { localize(localeContentKeys.confirmSubscription) }
            </Banner>
          </TableCell>
        );
    }
    if (removeTarget && removeTarget.id === lineItem.id) {
      return <TableCell colSpan={tableKeys.length}>
        <Banner
          icon={(<Icon icon={settings.svgIcons.remove} />)}
          width={settings.dimensions.full}
          height={settings.sizes.small}
          actions={(
            <>
              <Button onClick={handleConfirmRemove}>
                { localize(localeButtonKeys.remove) }
              </Button>
              <Button onClick={handleCancelRemove}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmRemove) }
        </Banner>
      </TableCell>
    }
    return null;
  }, [
    tableKeys,
    removeTarget,
    subscriptTarget,
    handleSaveSubscription,
    handleConfirmRemove,
    handleConfirmRemoveSubscription,
    handleCancelRemove,
  ]);

  const replaceRow = useCallback((lineItem : LineItem) => {
    if (subscriptTarget && subscriptTarget.lineItem?.id === lineItem.id) {
      return (<LineItemRow
        lineItem={subscriptTarget.lineItem}
        period={subscriptTarget.options?.period}
        tableKeys={tableKeys}
        editing={false}
        onSave={handleSaveNewLineItem}
        onCancel={handleCancelNewLineItem}
        onProductChange={handleNewProductChange}
      />);
    }
  }, [
    subscriptTarget,
    handleSaveNewLineItem,
    handleCancelNewLineItem,
    handleNewProductChange,
    tableKeys,
  ]);

  const generateExtraRow = useCallback(() => {
    if (newLineItem) {
      const lineItemRow = (
        <LineItemRow
          lineItem={{
            ...newLineItem,
            productId : newProduct?.id ?? newLineItem.productId,
          }}
          editing={true}
          mode="edit"
          onSave={handleSaveNewLineItem}
          onCancel={handleCancelNewLineItem}
          onProductChange={handleNewProductChange}
          tableKeys={tableKeys}
        />
      );

      const time = initialOrder?.time ?? new Date();
      if (newProduct && isProductCustomisable(newProduct, time)) {
        setExtraRow([
          lineItemRow,
          <LineItemSelectionsRow
            key={'selections'}
            lineItem={newLineItem}
            selections={newSelections}
            setSelection={handleNewSelections}
            colSpan={tableKeys.length}
            tableKeys={selectionTableKeys}
            extraRow={(
              <React.Fragment key={'extra'}>
                { selectionTableKeys.map((key) => {
                  switch(key) {
                    case TABLE_KEYS.actions:
                      return (
                        <TableActionCell
                          key={key}
                          width={settings.dimensions.xsmall}
                        >
                          <Action
                            label={localize(localeTableKeys.actions.add)}
                            onClick={handleAddNewSelection}
                          >
                            <Icon
                              icon={settings.svgIcons.add}
                            />
                          </Action>
                        </TableActionCell>
                      );
                    default: return (<TableCell key={key} />);
                  }
                }) }
              </React.Fragment>
            )}
            generateActions={(selection) => (
              <TableActionCell width={settings.dimensions.xsmall}>
                <Action
                  label={localize(localeTableKeys.actions.remove)}
                  onClick={handleRemoveNewSelection(selection)}
                  colour={settings.colours.button.alert}
                >
                  <Icon icon={settings.svgIcons.remove} />
                </Action>
              </TableActionCell>
            )}
            disabled={false}
          />,
        ]);
      }
      else setExtraRow(lineItemRow);
      return;
    }
    setExtraRow(
      (!disabled && onAddLineItem)
        ? <>
            { tableKeys.map((key) => {
              switch(key) {
                case TABLE_KEYS.actions:
                  return (
                    <TableActionCell key={key} width={settings.dimensions.xsmall}>
                      <Action
                        label={localize(localeTableKeys.actions.add)}
                        onClick={handleAddLineItem}
                      >
                        <Icon
                          icon={settings.svgIcons.add}
                        />
                      </Action>
                    </TableActionCell>
                  );
                default: return (<TableCell key={key} />);
              }
            }) }
          </>
        : undefined
    )
  }, [
    initialOrder,
    disabled,
    tableKeys,
    selectionTableKeys,
    newLineItem,
    newProduct,
    newSelections,
    handleAddLineItem,
    handleSaveNewLineItem,
    handleCancelNewLineItem,
    handleNewProductChange,
    handleNewSelections,
    handleAddNewSelection,
    handleRemoveNewSelection,
    onAddLineItem,
    isProductCustomisable,
  ]);

  useEffect(() => {
    if (active) return;
    setEditingItems([]);
    setNewLineItem(null);
    setNewSelections([]);
    setRemoveTarget(null);
    setSubscriptTarget(null);
  }, [active]);

  useEffect(() => {
    if (!order) return;
    const {
      addresses,
      customers,
      locations,
      schedules,
    } = evaluateOptions({ ...order, service : null });
    setAddresses(addresses);
    setCustomers(customers);
    setLocations(locations);
    setSchedules(schedules);
  }, [
    order,
    evaluateOptions,
    setAddresses,
    setCustomers,
    setLocations,
    setSchedules,
  ]);

  useEffect(() => {
    setLineItems(Object.values(order?.lineItems ?? []).sort(
      (a, b) => a.productId - b.productId,
    ));
    setSelections(Object.values(order?.selections ?? []).sort(
      (a, b) => a.assemblyId - b.assemblyId,
    ));
  }, [order]);

  useEffect(() => {
    if (!setItemsReady) return;
    setItemsReady(
      !!lineItems.length &&
      editingItems.length === 0 &&
      !newLineItem &&
      !removeTarget
    );
  }, [setItemsReady, lineItems, editingItems, newLineItem, removeTarget]);

  useEffect(() => {
    if (!editing) {
      setEditingItems([]);
      setNewLineItem(null);
      setNewSelections([]);
      setRemoveTarget(null);
    }
  }, [editing, setRemoveTarget]);

  useEffect(() => { retrieve() }, [retrieve]);
  useEffect(() => { generateExtraRow() }, [generateExtraRow]);

  useEffect(() => {
    if (!newLineItem) setNewProduct(null);
    else setNewProduct(
      listRecords(products).find(
        product => product.id === newLineItem.productId
      ) ?? null
    );
  }, [newLineItem, products]);

  useEffect(() => {
    if (subscriptTarget?.lineItem?.id
      && !subscriptTarget.deleting
      && !editingItems.includes(subscriptTarget.lineItem?.id)
    ) setSubscriptTarget(null);
  }, [subscriptTarget, editingItems]);

  useEffect(() => {
    if (mode === 'fulfilment') {
      const fulfilments = initialOrder?.order?.fulfilments;
      const lineItemIds = Object.values(fulfilments ?? []).map((f) => {
        return f.lineItemId
      }).filter((id) => !!id) as number[];
      setEditingItems([...lineItemIds]);
    }
  }, [initialOrder, mode]);

  useEffect(() => {
    setEditingItem?.(editingItems.length > 0 || !!newLineItem);
  }, [editingItems, newLineItem, setEditingItem]);

  return (
    <>
      <Form>
        <Select
          label={localize(localeFormKeys.labels.serviceChannel)}
          selected={order?.serviceChannel || null}
          options={serviceChannels}
          onChange={handleServiceChannel}
          disableClear
          errors={errors.serviceChannel}
          disabled={disabled || !editing || mode === 'fulfilment'}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={service => service?.name ?? ''}
          keyGenerator={service => `${service?.id}`}
          width={
            isDesktop
              ? settings.dimensions.quarter
              : settings.dimensions.half
          }
        />
        <Select
          label={localize(localeFormKeys.labels.location)}
          selected={order?.location || null}
          options={locations}
          onChange={handleLocation}
          errors={errors.location}
          disabled={disabled || !editing || mode === 'fulfilment'}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={service => service?.name ?? ''}
          keyGenerator={service => `${service?.id}`}
          width={
            isDesktop
              ? settings.dimensions.quarter
              : settings.dimensions.half
          }
        />
        <TimeSlotPicker
          schedules={schedules}
          date={order?.time}
          onChange={handleOrderTime}
          allowClear
          errors={errors.timeSlot}
          disabled={disabled || !editing || mode === 'fulfilment'}
          width={
            isDesktop
              ? settings.dimensions.half
              : settings.dimensions.full
          }
        />
        <Select
          label={localize(localeFormKeys.labels.customer)}
          selected={order?.customer || null}
          options={customers}
          onChange={handleCustomer}
          disableClear
          isEqual={(a, b) => a?.id === b?.id}
          disabled={
            disabled
              || !editing
              || mode === 'fulfilment'
              || disableCustomer
          }
          labelGenerator={customer => customer?.defaultName ?? ''}
          keyGenerator={customer => `${customer?.id}`}
          width={settings.dimensions.third}
        />
        <Select
          label={localize(localeFormKeys.labels.address)}
          selected={order?.address || null}
          options={addresses}
          onChange={handleAddress}
          errors={errors.address}
          disabled={disabled || !editing || mode === 'fulfilment'}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={
            address => address?.description ?? address?.street ?? ''
          }
          keyGenerator={address => `${address?.id}`}
          width={settings.dimensions.twoThirds}
        />
      </Form>
      <LineItemTable
        order={initialOrder}
        lineItems={lineItems}
        selections={selections}
        mode={mode}
        editing={editingItems}
        setEditing={setEditingItems}
        showNotes={showNotes}
        disabled={disabled || (!freeEdit && editing) || !!subscriptTarget}
        onSave={handleSave}
        onDeleteFulfilment={onDeleteFulfilment}
        generateActions={generateActions}
        beforeRow={generateConfirmationRow}
        extraRow={extraRow}
        replaceRow={replaceRow}
        onUpdateFulfilment={onUpdateFulfilment}
        disableProduct={disableProduct}
        tableKeys={tableKeys}
        selectionTableKeys={selectionTableKeys}
      />
    </>
  );
}

export default OrderForm;
