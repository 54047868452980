import React, { useCallback, useMemo, useState } from 'react';

import { Collection, Product } from '#types';

import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import useTags from '#hooks/useTags';
import useOptions from '#hooks/useOptions';

import Banner from '#materials/Banner';
import Button from '#materials/Button';
import Icon from '#materials/Icon';
import Segment from '#materials/Segment';
import Text from '#materials/Text';
import { settings } from '#materials';
import { TableActionCell, Action } from '#materials/TableCell';

import ProductTable from '#components/products/ProductTable';
import ProductSearch from '#components/products/ProductSearch';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.collections.collectionProducts;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.products;
const localeNotificationKeys = locale.keys.notifications.collections;

interface CollectionProductsProps {
  collection : Collection;
  onUpdate? (collection : Collection) : void;
}

function CollectionProducts(
{
  collection,
  onUpdate
} : CollectionProductsProps) {
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const {
    moveProductToTop,
    moveProductToBottom,
    moveProductUp,
    moveProductDown,
  } = useTags();
  const {
    addProductToCollection,
    removeProductFromCollection,
    addCollectionDefault,
    getCollectionProducts,
    getProductDefaultCount,
    getTotalDefaultCount,
  } = useOptions();

  const [editing, setEditing] = useState(false);
  const [removeTarget, setRemoveTarget] = useState<Product | null>(null);

  const collectionProducts = useMemo(
    () => getCollectionProducts(collection, { includeTags : false }),
    [collection, getCollectionProducts],
  );
  const allProducts = useMemo(
    () => getCollectionProducts(collection),
    [collection, getCollectionProducts],
  );

  const handleAdd = useCallback(() => {
    setEditing(true);
  }, []);

  const handleCancel = useCallback(() => {
    setEditing(false);
  }, []);

  const handleView = useCallback((product : Product) => () => {
    if (!product.id) return;
    navigate(`/products/${product.id}`);
  }, [navigate]);

  const handleAddProduct = useCallback((product : Product) => async () => {
    const success = await addProductToCollection(collection, product);
    if (onUpdate) onUpdate(collection);
    if (success) {
      createNotification({
        key : 'add-product-success',
        message : localize(localeNotificationKeys.addProduct.success),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'add-product-error',
        message : localize(localeNotificationKeys.addProduct.error),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [collection, onUpdate, addProductToCollection, createNotification]);

  const notifyOnMove = useCallback((success : boolean) => {
    if (success) {
      createNotification({
        key : 'move-product-success',
        message : localize(localeNotificationKeys.moveProduct.success),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'move-product-error',
        message : localize(localeNotificationKeys.moveProduct.error),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [createNotification]);

  const handleMoveProductToTop = useCallback(
    (product : Product) => async () => {
      const success = await moveProductToTop(collection, product);
      if (success && onUpdate) onUpdate(collection);
      notifyOnMove(!!success);
    },
    [collection, onUpdate, notifyOnMove, moveProductToTop],
  );

  const handleMoveProductToBottom = useCallback(
    (product : Product) => async () => {
      const success = await moveProductToBottom(collection, product);
      if (success && onUpdate) onUpdate(collection);
      notifyOnMove(!!success);
    },
    [collection, onUpdate, notifyOnMove, moveProductToBottom],
  );

  const handleMoveProductUp = useCallback(
    (product : Product) => async () => {
      const success = await moveProductUp(collection, product);
      if (success && onUpdate) onUpdate(collection);
      notifyOnMove(!!success);
    },
    [collection, onUpdate, notifyOnMove, moveProductUp],
  );

  const handleMoveProductDown = useCallback(
    (product : Product) => async () => {
      const success = await moveProductDown(collection, product);
      if (success && onUpdate) onUpdate(collection);
      notifyOnMove(!!success);
    },
    [collection, onUpdate, notifyOnMove, moveProductDown],
  );

  const setDefaultCount = useCallback(async (
    product : Product,
    count : number,
  ) => {
    const success = !!(await addCollectionDefault(collection, product, count));
    if (success && onUpdate) onUpdate(collection);
    if (success) {
      createNotification({
        key : 'set-default-success',
        message : localize(localeNotificationKeys.setDefault.success),
        icon : <Icon icon={settings.svgIcons.star} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'set-default-error',
        message : localize(localeNotificationKeys.setDefault.error),
        icon : <Icon icon={settings.svgIcons.star} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [collection, onUpdate, addCollectionDefault, createNotification]);

  const handleIncrementDefault = useCallback((product : Product) => () => {
    const defaultCount = getProductDefaultCount(collection, product);
    setDefaultCount(product, defaultCount + 1);
  }, [collection, setDefaultCount, getProductDefaultCount]);

  const handleDecrementDefault = useCallback((product : Product) => () => {
    const defaultCount = getProductDefaultCount(collection, product);
    setDefaultCount(product, defaultCount - 1);
  }, [collection, setDefaultCount, getProductDefaultCount]);

  const handleInitRemoveProduct = useCallback((product : Product) => () => {
    setRemoveTarget(product);
  }, [setRemoveTarget]);

  const handleCancelRemoveProduct = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleRemoveProduct = useCallback((product : Product) => async () => {
    const success = await removeProductFromCollection(collection, product);
    if (onUpdate) onUpdate(collection);

    setRemoveTarget(null);
    if (success) {
      createNotification({
        key : 'remove-product-success',
        message : localize(localeNotificationKeys.removeProduct.success),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-product-error',
        message : localize(localeNotificationKeys.removeProduct.error),
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [collection, onUpdate, createNotification, removeProductFromCollection]);

  const filterAvailableProducts = useCallback((product : Product) => {
    return !!product.id &&
      collection &&
      !collection.productIds.includes(product.id);
  }, [collection]);

  const filterTagProducts = useCallback((product : Product) => {
    return !collectionProducts.some((p) => p.id === product.id);
  }, [collectionProducts]);

  const generateActions = useCallback((product : Product) => {
    const defaultCount = getProductDefaultCount(collection, product);
    return (editing
      ? (filterTagProducts(product)
        ? (
          <TableActionCell>
            <Action
              label={localize(localeTableKeys.actions.addProduct)}
              onClick={handleAddProduct(product)}
            >
              <Icon icon={settings.svgIcons.add} />
            </Action>
          </TableActionCell>
        ) : (
          <TableActionCell>
            <Action
              label={localize(localeTableKeys.actions.moveTop)}
              onClick={handleMoveProductToTop(product)}
              disabled={collection.productIds[0] === product.id}
            >
              <Icon icon={settings.svgIcons.keyboardDoubleArrowUp} />
            </Action>
            <Action
              label={localize(localeTableKeys.actions.moveUp)}
              onClick={handleMoveProductUp(product)}
              disabled={collection.productIds[0] === product.id}
            >
              <Icon icon={settings.svgIcons.keyboardArrowUp} />
            </Action>
            <Action
              label={localize(localeTableKeys.actions.moveDown)}
              onClick={handleMoveProductDown(product)}
              disabled={collection.productIds[collection.productIds.length - 1]
                === product.id}
            >
              <Icon icon={settings.svgIcons.keyboardArrowDown} />
            </Action>
            <Action
              label={localize(localeTableKeys.actions.moveBottom)}
              onClick={handleMoveProductToBottom(product)}
              disabled={collection.productIds[collection.productIds.length - 1]
                === product.id}
            >
              <Icon icon={settings.svgIcons.keyboardDoubleArrowDown} />
            </Action>
            <Action
              label={localize(localeTableKeys.actions.removeProduct)}
              onClick={handleInitRemoveProduct(product)}
              colour={settings.colours.button.alert}
            >
              <Icon icon={settings.svgIcons.remove} />
            </Action>
          </TableActionCell>
        ))
      : (
        <TableActionCell>
          { !filterTagProducts(product) && (
            <>
              <Action
                label={localize(localeTableKeys.actions.decrementDefault)}
                onClick={handleDecrementDefault(product)}
                disabled={defaultCount < 1}
              >
                <Icon icon={settings.svgIcons.starOutline} />
              </Action>
              <Action
                label={`${localize(localeContentKeys.defaultCount)}: `
                  + defaultCount}
                disabled={defaultCount < 1}
              >
                { collection.defaults.find((p) => p.productId === product.id)
                  ?.quantity || 0 }
              </Action>
              <Action
                label={localize(localeTableKeys.actions.incrementDefault)}
                onClick={handleIncrementDefault(product)}
                disabled={getTotalDefaultCount(collection) >= collection.max}
              >
                <Icon icon={settings.svgIcons.star} />
              </Action>
            </>
          ) }
          <Action
            label={localize(localeTableKeys.actions.view)}
            onClick={handleView(product)}
          >
            <Icon icon={settings.svgIcons.shoppingBag} />
          </Action>
        </TableActionCell>
      )
    )
  }, [
    collection,
    editing,
    handleView,
    handleAddProduct,
    handleMoveProductToTop,
    handleMoveProductToBottom,
    handleMoveProductUp,
    handleMoveProductDown,
    handleIncrementDefault,
    handleDecrementDefault,
    handleInitRemoveProduct,
    filterTagProducts,
    getProductDefaultCount,
    getTotalDefaultCount,
  ]);

  const generateAddActions = useCallback((product : Product) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.addProduct)}
          onClick={handleAddProduct(product)}
        >
          <Icon icon={settings.svgIcons.add} />
        </Action>
      </TableActionCell>
    );
  }, [handleAddProduct]);

  return (
    <>
      <Segment title={localize(localeContentKeys.title)} />
      { !!removeTarget &&
        <Banner
          icon={<Icon icon={settings.svgIcons.remove} />}
          actions={(
            <>
              <Button
                onClick={handleRemoveProduct(removeTarget)}
              >
                { localize(localeButtonKeys.remove) }
              </Button>
              <Button
                onClick={handleCancelRemoveProduct}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmRemove) }
        </Banner>
      }
      { allProducts.length
        ? <ProductTable
          products={allProducts}
          generateActions={generateActions}
          fadeProducts={filterTagProducts}
        />
        : <Text>{ localize(localeContentKeys.noProducts) }</Text>
      }
      { editing
        ? <>
          <Segment title={localize(localeContentKeys.availableProducts)}>
            <ProductSearch
              pageCount={5}
              filter={filterAvailableProducts}
              generateActions={generateAddActions}
            />
            <Button
              colour={settings.colours.button.alert}
              onClick={handleCancel}
            >
              { localize(localeButtonKeys.close) }
            </Button>
          </Segment>
        </>
        : <Button
          onClick={handleAdd}
        >
          { localize(localeButtonKeys.edit) }
        </Button>
      }
    </>
  );
}

export default CollectionProducts;
