import React, { useCallback, useEffect, useState } from 'react';

import { Partial } from '#types';

import MuiTable from '@mui/material/Table';
import MuiTableHeader from '@mui/material/TableHead';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableRow from '@mui/material/TableRow';

import { Alignment, Dimension, convert, settings } from '#materials';
import { CellElement } from '#materials/TableCell';
import Pagination from '#materials/Pagination';

import { generateRange } from '#utils/array';

interface BaseTableProps {
  id? : string;
  pageCount? : number;
  length? : number;
  rows? : React.ReactNode[];
  width? : Dimension;
  rowDivider? : boolean;
  alignment? : Alignment;
};

interface TableGridProps extends BaseTableProps {
  keys : string[];
  length : number;
  starting? : number;
  headGenerator : (key : string) => CellElement;
  rowGenerator : (key : string, index : number) => CellElement;
};

interface TableRowProps extends BaseTableProps {
  head : React.ReactNode;
  rows : React.ReactNode[];
};

interface TableProps
  extends
    BaseTableProps,
    Partial<TableGridProps>,
    Partial<TableRowProps> {};

function isGridProps(props : TableProps) : props is TableGridProps {
  return (
    ('keys' in props) &&
    ('length' in props) &&
    ('headGenerator' in props) &&
    ('rowGenerator' in props)
  );
}

function isRowProps(props : TableProps) : props is TableRowProps {
  return (
    ('head' in props) &&
    ('rows' in props)
  );
}

function GridTable({
  id,
  keys,
  length,
  starting = 0,
  headGenerator,
  rowGenerator,
  width = settings.dimensions.full,
  alignment,
  rowDivider = true,
} : TableGridProps) {
  const [head, setHead] = useState<React.ReactNode>(null);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    setHead(keys.map(key => (
      <React.Fragment key={`${id}-header-${key}`}>
        { headGenerator(key) }
      </React.Fragment>
    )));
  }, [id, keys, headGenerator]);

  useEffect(() => {
    setRows(generateRange(length, starting).map(i => (
      <MuiTableRow
        key={`${id}-row-${i}`}
        sx={{
          borderStyle : 'solid',
          borderColor : 'lightgrey',
          borderWidth : 0,
          borderBottomWidth : (!rowDivider || (i === (length - 1))) ? 0 : 1,
          verticalAlign : 'top',
        }}
      >
        { keys.map(key => (
          <React.Fragment key={`${id}-cell-${key}-${i}`}>
            { rowGenerator(key, i) }
          </React.Fragment>
        )) }
      </MuiTableRow>
    )));
  }, [id, keys, length, starting, rowDivider, rowGenerator]);

  return (
    <MuiTable
      size='small'
      sx={{
        width : convert.width(width),
        ...(alignment === settings.alignments.center && { margin : 'auto' }),
        ...(alignment === settings.alignments.left && { mr : 'auto' }),
        ...(alignment === settings.alignments.right && { ml : 'auto' }),
        mb : [2],
      }}
    >
      <MuiTableHeader>
        <MuiTableRow
          sx={{
            borderStyle : 'solid',
            borderWidth : 0,
            borderBottomWidth : 2,
            verticalAlign : 'top',
          }}
        >
          { head }
        </MuiTableRow>
      </MuiTableHeader>
      <MuiTableBody>
        { rows }
      </MuiTableBody>
    </MuiTable>
  );
};

function RowTable({
  id,
  head,
  rows,
  width = settings.dimensions.full,
  alignment,
  rowDivider = true,
} : TableRowProps) {
  const [renderedRows, setRenderedRows] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    setRenderedRows(rows.map((row, i) => (
      <MuiTableRow
        key={`${id}-row-${i}`}
        sx={{
          borderStyle : 'solid',
          borderColor : 'lightgrey',
          borderWidth : 0,
          ...((head === null && i === 0) && { borderTopWidth : 0 }),
          borderBottomWidth : (!rowDivider || (i === (rows.length - 1)))
            ? 0 : 1,
          verticalAlign : 'top',
        }}
      >
        { row }
      </MuiTableRow>
    )));
  }, [id, head, rows, rowDivider]);

  return (
    <MuiTable
      size='small'
      sx={{
        width : convert.width(width),
        ...(alignment === settings.alignments.center && { margin : 'auto' }),
        ...(alignment === settings.alignments.left && { mr : 'auto' }),
        ...(alignment === settings.alignments.right && { ml : 'auto' }),
      }}
    >
      { head && (
        <MuiTableHeader>
          <MuiTableRow
            sx={{
              borderStyle : 'solid',
              borderWidth : 0,
              borderBottomWidth : 2,
              verticalAlign : 'top',
            }}
          >
            { head }
          </MuiTableRow>
        </MuiTableHeader>
      ) }
      <MuiTableBody>
        { renderedRows }
      </MuiTableBody>
    </MuiTable>
  );
}

function Table(props : TableGridProps) : React.ReactElement;
function Table(props : TableRowProps) : React.ReactElement;
function Table({ pageCount, ...props } : TableProps) {
  const [page, setPage] = useState<number>(1);

  const handleChange = useCallback(
    (event : React.ChangeEvent<unknown>, page : number) => {
      setPage(page);
    },
    [setPage],
  );

  const isGrid = isGridProps(props);
  const isRow = isRowProps(props);
  if (!isGrid && !isRow) {
    console.error('Table props are not valid.');
    return (<></>);
  }

  const numberOfRows = isGrid ? props.length : props.rows.length;
  const numberOfPages = pageCount ?
    Math.ceil(numberOfRows / pageCount)
    : 1;
  const showPagination = numberOfPages > 1;

  const pageLength = (showPagination && pageCount)
    ? ((page === numberOfPages)
      ? numberOfRows - (pageCount * (page - 1))
      : pageCount)
    : numberOfRows
  const startingAt = (showPagination && pageCount)
    ? (pageCount * (page - 1))
    : 0;

  return (
    <>
      { isGrid
        ? <GridTable
          {...props}
          length={ pageLength }
          starting={ startingAt }
        />
        : <RowTable
          {...props}
          rows={ props.rows.slice(startingAt, startingAt + pageLength) }
        />
      }
      { showPagination &&
        <Pagination
          count={numberOfPages}
          page={page}
          onChange={handleChange}
        />
      }
    </>
  )
}

export default Table;
