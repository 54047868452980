import React, { useCallback, useMemo, useState } from 'react';

import { Assembly, Product } from '#types';

import useNotifications from '#hooks/useNotifications';
import useOptions from '#hooks/useOptions';

import { settings } from '#materials';
import Form from '#materials/Form';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Select from '#materials/Select';
import Tabs from '#materials/Tabs';

import AssemblyDetails from '#components/assemblies/AssemblyDetails';
import CreateAssembly from '#components/assemblies/CreateAssembly';
import Section from '#components/dashboard/Section';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.products.productOptions;
const localeNotificationKeys = locale.keys.notifications.products;
const localeButtonKeys = locale.keys.buttons;
const localeFormKeys = locale.keys.forms.products;

interface ProductAssembliesProps {
  product : Product;
};

const ProductAssemblies = ({ product } : ProductAssembliesProps) => {
  const { createNotification } = useNotifications();
  const {
    assemblies,
    addAssemblyToProduct,
    getProductAssemblies,
  } = useOptions();

  const [tab, setTab] = useState<string>('');
  const [assemblyToAdd, setAssemblyToAdd] = useState<Assembly | null>(null);

  const availableAssemblies = useMemo(
    () => listRecords(assemblies)
      .filter((a) => product.id && !a.productIds.includes(product.id)),
    [product, assemblies],
  );
  const productAssemblies = useMemo(
    () => getProductAssemblies(product),
    [product, getProductAssemblies],
  );

  const addAssembly = useCallback(async () => {
    if (!assemblyToAdd) return;
    const success = !!(await addAssemblyToProduct(product, assemblyToAdd));

    if (success) {
      createNotification({
        key : 'add-assembly-success',
        message : localize(localeNotificationKeys.assemblyAdded.success),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      });
    } else {
      createNotification({
        key : 'add-assembly-error',
        message : localize(localeNotificationKeys.assemblyAdded.error),
        colour : settings.colours.alert.alert,
        icon : <Icon icon={settings.svgIcons.shoppingBag} />,
      });
    }
  }, [product, assemblyToAdd, addAssemblyToProduct, createNotification]);

  const assemblyTabs = useMemo(() => {
    return [
      ...productAssemblies.map((assembly) => {
        return {
          tabName : `${assembly.name} (#${assembly.id})`,
          tabContent : <AssemblyDetails
            product={product}
            assembly={assembly}
          />,
        }
      }),
      {
        tabName : localize(localeButtonKeys.new),
        tabContent: (
          <>
            <Form>
              <Button
                onClick={addAssembly}
                disabled={!assemblyToAdd}
              >
                { localize(localeButtonKeys.add) }
              </Button>
              <Select
                label={localize(localeFormKeys.labels.assembly)}
                options={availableAssemblies}
                selected={assemblyToAdd}
                onChange={setAssemblyToAdd}
                labelGenerator={(assembly) => assembly?.name ?? ''}
              />
            </Form>
            <CreateAssembly product={product} />
          </>
        ),
        tabIcon: <Icon icon={settings.svgIcons.add} />
      }
    ];
  }, [
    product,
    availableAssemblies,
    productAssemblies,
    assemblyToAdd,
    addAssembly,
  ]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      <Tabs tab={tab} setTab={setTab} tabObjects={assemblyTabs}/>
    </Section>
  )
}

export default ProductAssemblies;
