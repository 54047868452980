import { useContext, useCallback } from 'react';

import { Product, Tag, isProduct, isTag } from '#mrktbox/clerk/types';

import TagContext from '#mrktbox/clerk/context/TagContext';

import useProducts from '#mrktbox/clerk/hooks/useProducts';

export function generateDefaultTag() : Tag {
  return {
    name : '',
    productIds : [],
    availableChannelIds : [],
  };
}

function useTags() {
  const {
    tags,
    loaded,
    load,
    refreshTags,
    refreshTag,
    createTag,
    retrieveTags,
    retrieveTag,
    updateTag,
    deleteTag,
    addProductToTag,
    removeProductFromTag,
    addServiceChannelToTag,
    removeServiceChannelFromTag,
  } = useContext(TagContext);

  const { products } = useProducts();

  const addProduct = useCallback(
    async (
      tag: Product | Tag,
      product: Product | Tag,
      options? : { before? : Product },
    ) => {
      if (isTag(tag) && isProduct(product)) {
        return await addProductToTag(tag, product, options);
      } else if (isProduct(tag) && isTag(product)) {
        // DEPR: (product, tag) => boolean
        return await addProductToTag(product, tag, options);
      } else {
        throw new Error('Invalid argument combination');
      }
    },
    [addProductToTag],
  );

  const removeProduct = useCallback(
    async (tag: Product | Tag, product: Product | Tag) => {
      if (isTag(tag) && isProduct(product)) {
        return await removeProductFromTag(tag, product);
      } else if (isProduct(tag) && isTag(product)) {
        // DEPR: (product, tag) => boolean
        return await removeProductFromTag(product, tag);
      } else {
        throw new Error('Invalid argument combination');
      }
    },
    [removeProductFromTag],
  );

  const moveProductToTop = useCallback(async (tag : Tag, product : Product) => {
    const firstProduct = tag.productIds
      .map(i => products?.[i])
      .find((p) => !!p)
    if (!firstProduct) return false;
    return await addProductToTag(tag, product, { before : firstProduct });
  }, [products, addProductToTag]);

  const moveProductToBottom = useCallback(async (
    tag : Tag,
    product : Product
  ) => {
    return await addProductToTag(tag, product);
  }, [addProductToTag]);

  const moveProductUp = useCallback(async (tag : Tag, product : Product) => {
    const index = product.id ? tag.productIds.indexOf(product.id) : -1;
    if (index === -1) return false;

    const ids = tag.productIds.slice(0, index).reverse();
    const lastProduct = ids
      .map(i => products?.[i])
      .find((p) => !!p)
    if (!lastProduct) return false;

    return await addProductToTag(tag, product, { before : lastProduct });
  }, [products, addProductToTag]);

  const moveProductDown = useCallback(async (tag : Tag, product : Product) => {
    const index = product.id ? tag.productIds.indexOf(product.id) : -1;
    if (index === -1) return false;

    const ids = tag.productIds.slice(index + 2);
    const nextProduct = ids
      .map(i => products?.[i])
      .find((p) => !!p) ?? undefined;
    if (ids.length && !nextProduct) return false;
    return await addProductToTag(tag, product, { before : nextProduct });
  }, [products, addProductToTag]);

  const getTagProducts = useCallback((tag : Tag) => {
    return tag.productIds
      .map(productId => products?.[productId])
      .filter((product) => !!product) as Product[];
  }, [products]);

  return {
    tags,
    loaded,
    load,
    refreshTags,
    refreshTag,
    createTag,
    retrieveTags,
    retrieveTag,
    updateTag,
    deleteTag,
    addProductToTag : addProduct,
    removeProductFromTag : removeProduct,
    addServiceChannelToTag,
    removeServiceChannelFromTag,
    getTagProducts,
    moveProductToTop,
    moveProductUp,
    moveProductDown,
    moveProductToBottom,
  };
}

export default useTags;
