import React, { useCallback } from 'react';

import { Product } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import ListInput from '#materials/ListInput';
import Form from '#materials/Form';
import Switch from '#materials/Switch';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.products;

interface ProductFormProps {
  product : Product;
  detailed : boolean;
  hasNotes? : boolean;
  requiresNotes? : boolean;
  onSubmit? : () => void;
  onHasNotesChange? : (hasNotes : boolean) => void;
  onRequiresNotesChange? : (requiresNotes : boolean) => void;
}

function ProductForm({
  product : fallback,
  detailed,
  hasNotes,
  requiresNotes,
  onSubmit,
  onHasNotesChange,
  onRequiresNotesChange,
} : ProductFormProps) {
  const { state, dispatch, editing } = useForm<Product>();

  const setName = useCallback((name : string) => {
    dispatch({ name });
  }, [dispatch]);

  const setSku = useCallback((sku : string) => {
    dispatch({ sku });
  }, [dispatch]);

  const setSummary = useCallback((summary : string) => {
    dispatch({ summary });
  }, [dispatch]);

  const setDescription = useCallback((description : string) => {
    dispatch({ description });
  }, [dispatch]);

  const setIngredients = useCallback((ingredients : string[]) => {
    dispatch({ ingredientsList : ingredients });
  }, [dispatch]);

  const setPrice = useCallback((price : number | null) => {
    if (price === null) return;
    dispatch({ price : {
      'amount' : Math.round(price * 100),
      'calculatedValue' : price,
      'currencyCode' : 'CAD',
      'increment' : 0.01,
    } });
  }, [dispatch]);

  const idPrefix = (fallback && fallback.id)
    ? `product-${fallback?.id}`
    : 'product-new';

  return (
    <Form onSubmit={onSubmit}>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localKeys.labels.name)}
        value={state ? state.name : fallback.name}
        onChange={setName}
        disabled={!editing}
        width={settings.dimensions.half}
      />
      <TextInput
        id={`${idPrefix}-sku`}
        label={localize(localKeys.labels.sku)}
        value={state ? state.sku : fallback.sku}
        onChange={setSku}
        disabled={!editing}
        width={settings.dimensions.quarter}
      />
      <TextInput
        id={`${idPrefix}-price`}
        label={localize(localKeys.labels.price)}
        value={
          state
          ? state.price.calculatedValue
          : fallback.price.calculatedValue
        }
        onChange={setPrice}
        inputType={settings.inputType.number}
        inputFormat={settings.inputFormat.currency}
        disabled={!editing}
        width={settings.dimensions.quarter}
      />
      { detailed &&
        <>
          { hasNotes !== undefined && (
            <Switch
              checked={hasNotes}
              label={localize(localKeys.labels.hasNotes)}
              onChange={onHasNotesChange}
              disabled={!editing}
              width={settings.dimensions.quarter}
            />
          ) }
          { requiresNotes !== undefined && (
            <Switch
              checked={!!hasNotes && requiresNotes}
              label={localize(localKeys.labels.requireNotes)}
              onChange={onRequiresNotesChange}
              disabled={!hasNotes || !editing}
              width={settings.dimensions.quarter}
            />
          ) }
          <TextInput
            id={`${idPrefix}-summary`}
            label={localize(localKeys.labels.summary)}
            value={state ? state.summary : fallback.summary}
            onChange={setSummary}
            maxLength={255}
            disabled={!editing}
            width={settings.dimensions.full}
            minRows={2}
            maxRows={2}
          />
          <TextInput
            id={`${idPrefix}-description`}
            label={localize(localKeys.labels.description)}
            value={state ? state.description : fallback.description}
            onChange={setDescription}
            disabled={!editing}
            width={settings.dimensions.full}
            minRows={2}
          />
          <ListInput
            label={localize(localKeys.labels.ingredients)}
            values={
              (state ? state.ingredientsList : fallback.ingredientsList) ?? []
            }
            onChange={setIngredients}
            disabled={!editing}
            width={settings.dimensions.full}
          />
        </>
      }
    </Form>
  );
}

export default ProductForm;
