export type InputHandlerArgs = {
  ref : React.RefObject<HTMLInputElement>;
  value : string;
  selectionStart? : number;
  selectionEnd? : number;
};
export type InputHandler = (props : InputHandlerArgs) => void;

interface EventHandelerProps {
  ref : React.RefObject<HTMLInputElement>;
  setInput : InputHandler;
  deferedInput? : string;
  setDeferedInput? : (value: string) => void;
}
interface KeyDownHandlerProps extends EventHandelerProps {
  event : React.KeyboardEvent<HTMLInputElement>;
}
export type KeyDownHandler = (props : KeyDownHandlerProps) => void;

interface SelectHandlerProps extends EventHandelerProps {
  event : React.FocusEvent<HTMLInputElement>;
}
export type SelectHandler = (props : SelectHandlerProps) => void;

export interface BlurHandlerArgs extends EventHandelerProps {
  event : React.FocusEvent<HTMLInputElement>;
}
export type BlurHandler = (props : BlurHandlerArgs) => void;

export function setInput({
  ref,
  value,
  selectionStart,
  selectionEnd,
} : InputHandlerArgs) {
  if (!ref.current) return;
  const start = ref.current.selectionStart ?? 0;
  const end = ref.current.selectionEnd ?? 0;

  setTimeout(() => {
    if (!ref.current) return;
    ref.current.value = value;
    ref.current.selectionStart = selectionStart ?? start;
    ref.current.selectionEnd = selectionEnd ?? selectionStart ?? end;
  }, 0);
}

export function printFloat(value : number, precision? : number): string {
  if (precision !== undefined) {
    return value.toFixed(precision ?? 1);
  } else {
    let s = value.toString();
    if (s.indexOf('.') === -1) {
      s += '.0';
    }
    return s;
  }
}

export function cleanFloat(
  value: string,
  concise = false,
  precision? : number,
): string | undefined {
  if (value === '.') {
    return '0';
  }

  const n = Number(value);
  if (isNaN(n)) return undefined;

  const formatted = printFloat(n, precision);
  let zeroCount = 0;
  for (let i = value.length - 1; i >= 1; i--) {
    if (value[i] === '0' && value[i - 1] !== '.') zeroCount++;
    else break;
  }
  if (!concise && formatted === value.substring(0, value.length - zeroCount)) {
    return value;
  }

  return printFloat(n, precision);
}

export function handleFloatKeyDown(precision? : number) {
  return ({
    ref,
    event,
    setInput,
  } : KeyDownHandlerProps) => {
    if (!ref.current) return;
    const input = ref.current.value;
    const start = ref.current.selectionStart ?? 0;
    const end = ref.current.selectionEnd ?? 0;
    const key = event.key;
    const num = parseInt(key);

    const decimalPos = input.indexOf('.');
    const inputPrecision = decimalPos === -1
      ? 0
      : input.length - decimalPos - 1;

    if (key === 'Delete') {
      if (start !== end) {
        if (start < decimalPos && end > decimalPos) {
          event.preventDefault();
        }
      } else if (start === decimalPos) {
        setInput({
          ref,
          value : input,
          selectionStart : start + 1,
          selectionEnd : start + 1,
        })
        event.preventDefault();
      } else if (
        precision !== undefined &&
        start > decimalPos &&
        start < decimalPos + precision + 1
      ) {
        setInput({
          ref,
          value : input.substring(0, start) + input.substring(start + 1) + '0',
          selectionStart : start,
          selectionEnd : start,
        })
        event.preventDefault();
      }
    } else if (key === 'Backspace') {
      if (start === 1) {
        setInput({
          ref,
          value : '0' + input.substring(1),
          selectionStart : start,
          selectionEnd : start,
        });
        event.preventDefault();
      } else if (start !== end && start < decimalPos && end > decimalPos) {
        event.preventDefault();
      } else if (input.substring(0, 1) === '0' && start === 1) {
        event.preventDefault();
      } else if (start === decimalPos + 1) {
        setInput({
          ref,
          value : input,
          selectionStart : start - 1,
          selectionEnd : start - 1,
        });
        event.preventDefault();
      } else if (
        precision !== undefined &&
        start > decimalPos &&
        start <= decimalPos + precision + 1
      ) {
        setInput({
          ref,
          value : input.substring(0, start - 1) + input.substring(start) + '0',
          selectionStart : start - 1,
          selectionEnd : start - 1,
        })
        event.preventDefault();
      }
    } else if (key === '.') {
      if (start !== end) {
        if (start <= decimalPos && end > decimalPos) {
          setInput({
            ref,
            value : input.substring(0, start) + '.' + input.substring(end),
            selectionStart : start + 1,
            selectionEnd : start + 1,
          })
        }
      } else if (start === decimalPos) {
        setInput({
          ref,
          value : input,
          selectionStart : start + 1,
          selectionEnd : start + 1,
        })
      }
      event.preventDefault();
    } else if (key === 'ArrowLeft') {
      if (input.substring(0, 1) === '0' && start === 1) {
        event.preventDefault();
      }
    } else if (!isNaN(num)) {
      if (start === 1 && input.substring(0, 1) === '0') {
        setInput({
          ref,
          value : key + input.substring(1),
          selectionStart : start,
          selectionEnd : start,
        });
        event.preventDefault();
      } else if (
        start === end &&
        start === input.length &&
        precision !== undefined &&
        inputPrecision >= precision
      ) {
        event.preventDefault();
      } else if (
        start === end &&
        start > decimalPos &&
        precision !== undefined &&
        inputPrecision >= precision
      ) {
        setInput({
          ref,
          value : input.substring(0, start) +
            key +
            input.substring(start, decimalPos + precision),
          selectionStart : start + 1,
          selectionEnd : start + 1,
        });
        event.preventDefault();
      }
    }
  };
}

export function handleFloatSelect({
  ref,
  setInput,
} : SelectHandlerProps) {
  if (!ref.current) return;
  const input = ref.current.value;
  const start = ref.current.selectionStart ?? 0;
  const end = ref.current.selectionEnd ?? 0;

  if (start !== end) return;
  if (input.substring(0, 1) === '0' && start === 0) {
    setInput({
      ref,
      value : input,
      selectionStart : 1,
      selectionEnd : 1,
    });
  }
}

export function handleFloatBlur({
  ref,
  setInput,
} : BlurHandlerArgs) {
  if (!ref.current) return;
  const n = Number(ref.current.value);
  setInput({
    ref,
    value : !isNaN(n) ? printFloat(n) : '0.0',
  });
}

export function printTime(milliseconds: number) {
  const hours = Math.min(Math.floor(milliseconds / 3600000), 24);
  const minutes = Math.min(Math.floor((milliseconds % 3600000) / 60000), 59);
  return `${hours.toString().padStart(2, '0')}:` +
    minutes.toString().padStart(2, '0');
}

export function parseTime(input: string) {
  const [hour, minute] = input.split(':');
  const parsedDate = new Date(0);

  if (isNaN(Number(hour)) || isNaN(Number(minute))) return undefined;
  parsedDate.setUTCHours(Number(hour));
  parsedDate.setUTCMinutes(Number(minute));
  return parsedDate.getTime();
}

export function cleanTime(input: string) {
  const parts = input.split(':');
  if (parts.length !== 2) return undefined;
  if (parts.some((p) => isNaN(Number(p)))) return undefined;
  return input;
}

function setHours(
  ref : React.RefObject<HTMLInputElement>,
  hours : number,
  setInput : InputHandler,
  selectNext : boolean = false,
  max = 24 * 3600000,
) {
  const colon = ref.current?.value.indexOf(':') ?? 0;
  const fixed = (max === 24) && (colon === 2);

  const m = parseInt(ref.current?.value.substring(colon + 1) ?? '');
  const time = !isNaN(m)
    ? ((hours * 3600000) + (m * 60000))
    : (hours * 3600000);

  if (time > max) {
    const maxHours = Math.floor(max / 3600000);
    const maxMinutes = Math.floor((max % 3600000) / 60000);
    setInput({
      ref,
      value : (fixed
        ? `${maxHours.toString().padStart(2, '0')}:`
        : `${maxHours.toString()}:`) + maxMinutes.toString().padStart(2, '0'),
      selectionStart : selectNext ? maxHours.toString().length + 1 : undefined,
      selectionEnd : selectNext ? maxHours.toString().length + 3 : undefined,
    });
    return;
  }

  setInput({
    ref,
    value : ((hours > 9 || !fixed) ? '' : '0') + hours.toString()
      + (ref.current?.value ?? '').substring(colon),
    selectionStart : selectNext ? (colon + 1) : undefined,
    selectionEnd : selectNext ? ref.current?.value.length : undefined,
  });
}

function setMinutes(
  ref : React.RefObject<HTMLInputElement>,
  minutes : number,
  setInput : InputHandler,
) {
  const colon = ref.current?.value.indexOf(':') ?? 0;
  setInput({
    ref,
    value : (ref.current?.value ?? '').substring(0, colon + 1) +
      (minutes > 9 ? '' : '0') + minutes.toString(),
    selectionStart : colon + 1,
    selectionEnd : ref.current?.value.length,
  });
}

export function handleHoursKeyDown({
  ref,
  event,
  setInput,
  deferedInput = '',
  setDeferedInput = () => {},
} : KeyDownHandlerProps) {
  if (!ref.current) return;
  const key = event.key;

  const value = parseInt(key);
  if (isNaN(value)) return;
  if (deferedInput) {
    const newValue = parseInt(deferedInput + key) <= 24
      ? parseInt(deferedInput + key)
      : 24;
    if (!isNaN(newValue)) setHours(ref, newValue, setInput, true);
    setDeferedInput('');
  } else {
    if (value <= 2) {
      setDeferedInput(key);
      setHours(ref, value * 10, setInput);
    } else {
      setHours(ref, value, setInput);
    }
  }
}

export function handleMinutesKeyDown({
  ref,
  event,
  setInput,
  deferedInput = '',
  setDeferedInput = () => {},
  max24 = true,
} : KeyDownHandlerProps & { max24? : boolean }) {
  if (!ref.current) return;

  if (max24 && ref.current.value.substring(0, 2) === '24') {
    setMinutes(ref, 0, setInput);
    return;
  }

  const key = event.key;
  const value = parseInt(key);
  if (isNaN(value)) return;
  if (deferedInput) {
    const newValue = parseInt(deferedInput + key);
    if (!isNaN(newValue)) setMinutes(ref, newValue, setInput);
    setDeferedInput('');
  } else {
    if (value < 6) {
      setDeferedInput(key);
      setMinutes(ref, value * 10, setInput);
    } else {
      setMinutes(ref, value, setInput);
    }
  }
}

export function handleTimeInput({
  ref,
  event,
  setInput,
  deferedInput = '',
  setDeferedInput = () => {},
} : KeyDownHandlerProps) {
  if (!ref.current) return;
  const start = ref.current.selectionStart ?? 0;
  const key = event.key;

  const hourSelected = 0 <= start && start <= 2;
  const minuteSelected = 3 <= start;

  const value = parseInt(key);
  if (!isNaN(value)) {
    if (hourSelected) {
      handleHoursKeyDown({ ref, event, setInput, deferedInput, setDeferedInput });
    } else if (minuteSelected) {
      handleMinutesKeyDown({
        ref,
        event,
        setInput,
        deferedInput,
        setDeferedInput
      });
    }
    event.preventDefault();
  } else if (key === 'Backspace' || key === 'Delete') {
    if (start === 0) setHours(ref, 0, setInput);
    else if (start === 3) setMinutes(ref, 0, setInput);
    setDeferedInput('');
    event.preventDefault();
  } else if (key === 'ArrowLeft') {
    if (minuteSelected) {
      ref.current.selectionStart = 0;
      ref.current.selectionEnd = 2;
      setDeferedInput('');
    }
    event.preventDefault();
  } else if (key === 'ArrowRight') {
    if (hourSelected) {
      ref.current.selectionStart = 3;
      ref.current.selectionEnd = 5;
      setDeferedInput('');
    }
    event.preventDefault();
  }
}

export function handleTimeSelect({
  ref,
  event,
  setDeferedInput = () => {},
} : SelectHandlerProps) {
  if (!ref.current) return;
  const start = ref.current.selectionStart ?? 0;

  if (start < 3) {
    ref.current.selectionStart = 0;
    ref.current.selectionEnd = 2;
    setDeferedInput('');
  } else {
    ref.current.selectionStart = 3;
    ref.current.selectionEnd = 5;
    setDeferedInput('');
  }
  event.preventDefault();
}

export function printDuration(milliseconds: number) {
  const hours = Math.floor(milliseconds / 3600000);
  const minutes = Math.floor((milliseconds % 3600000) / 60000);
  return (hours ? `${hours.toString()}:` : '0:') +
    minutes.toString().padStart(2, '0');
}

export function parseDuration(input: string) {
  const [hour, minute] = input.split(':');
  if (isNaN(Number(hour)) || isNaN(Number(minute))) return undefined;
  const hours = Number(hour);
  const minutes = Number(minute);
  return (hours * 3600000) + (minutes * 60000);
}

export function handleDurationInput(options? : { max? : number }) {
  function handle({
    ref,
    event,
    setInput,
    deferedInput = '',
    setDeferedInput = () => {},
  } : KeyDownHandlerProps) {
    if (!ref.current) return;
    const start = ref.current.selectionStart ?? 0;
    const end = ref.current.selectionEnd ?? 0;
    const key = event.key;

    const colon = ref.current.value.indexOf(':');
    const hourSelected = (0 <= start) && (start <= colon);
    const minuteSelected = colon < start;

    const value = parseInt(key);
    if (!isNaN(value)) {
      if (minuteSelected) {
        const hours = parseInt(ref.current.value.substring(0, colon));
        const minutes = deferedInput
          ? parseInt(deferedInput + key)
          : value * 10;
        const time = ((hours * 60) + minutes) * 60000;
        if (!options?.max || time <= options.max) {
          handleMinutesKeyDown({
            ref,
            event,
            setInput,
            deferedInput,
            setDeferedInput,
            max24 : false,
          });
        } else {
          setDeferedInput('');
        }
        event.preventDefault();
      } else if (hourSelected) {
        const hours = parseInt(ref.current.value.substring(0, start) + key
          + ref.current.value.substring(end, colon));
        const minutes = parseInt(ref.current.value.substring(colon + 1));
        const time = ((hours * 60) + minutes) * 60000;
        if (options?.max && (time > options.max)) {
          setHours(ref, 8888, setInput, true, options.max);
          event.preventDefault();
        } else if (
          start === 1
            && colon === 1
            && ref.current.value.substring(0, 1) === '0'
        ) {
          setHours(ref, value, setInput, false);
          event.preventDefault();
        } else if ((start === 0) && (value === 0)) {
          event.preventDefault();
        }
      } else {
        event.preventDefault();
      }
    } else if (key === 'Backspace') {
      if (minuteSelected) {
        setMinutes(ref, 0, setInput);
        event.preventDefault();
      } else if (start === end) {
        if (start === 1 && colon === 1) {
          setHours(ref, 0, setInput, false);
          ref.current.selectionStart = 1;
          ref.current.selectionEnd = 1;
          event.preventDefault();
        }
      } else if ((start === 0) && (end === colon)) {
        setHours(ref, 0, setInput, false);
        ref.current.selectionStart = 1;
        ref.current.selectionEnd = 1;
        event.preventDefault();
      }
      setDeferedInput('');
    } else if (key === 'Delete') {
      if (minuteSelected) {
        setMinutes(ref, 0, setInput);
        event.preventDefault();
      } else if (start === end) {
        if (start === 0 && colon === 1) {
          setHours(ref, 0, setInput, true);
          event.preventDefault();
        }
      } else if ((start === 0) && (end === colon)) {
        setHours(ref, 0, setInput, false);
        ref.current.selectionStart = 1;
        ref.current.selectionEnd = 1;
        event.preventDefault();
      }
      setDeferedInput('');
    } else if (key === 'ArrowLeft') {
      if (minuteSelected) {
        ref.current.selectionStart = 0;
        ref.current.selectionEnd = colon;
        setDeferedInput('');
        event.preventDefault();
      }
    } else if (key === 'ArrowRight') {
      if (minuteSelected) {
        event.preventDefault();
      }
    } else if (key === ':') {
      ref.current.selectionStart = colon + 1;
      ref.current.selectionEnd = colon + 1;
      setDeferedInput('');
      event.preventDefault();
    } else {
      event.preventDefault();
    }
  }

  return handle;
}

export function handleDurationSelect({
  ref,
  event,
  setDeferedInput = () => {},
} : SelectHandlerProps) {
  if (!ref.current) return;
  const start = ref.current.selectionStart ?? 0;

  const colon = ref.current.value.indexOf(':');

  if (start <= colon) {
    setDeferedInput('');
  } else {
    ref.current.selectionStart = colon + 1;
    ref.current.selectionEnd = ref.current.value.length;
    event.preventDefault();
    setDeferedInput('');
  }
}
