import React, { useCallback, useState } from 'react';

import { ProjectedOrder } from '#types';

import  { FormProvider } from '#context/FormContext';

import useForm from '#hooks/useForm';
import useOrders from '#hooks/useOrders';

import Tabs from '#materials/Tabs';

import Section from '#components/dashboard/Section';
import OrderTotals from '#components/orders/OrderTotals';
import OrderFulfilment from '#components/orders/OrderFulfilment';
import EditOrder from '#components/orders/EditOrder';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.orderDetails;
const localeFormKeys = locale.keys.forms.orders;

interface OrderDetailsProps {
  order : ProjectedOrder;
}

function OrderDetailsControl({ order : initialOrder } : OrderDetailsProps) {
  const { state : order } = useForm<ProjectedOrder>();

  const [tab, setTab] = useState<'fulfilment' | 'edit'>('fulfilment');

  const handleSetTab = useCallback((tab : string) => {
    if (![ 'fulfilment', 'edit' ].includes(tab)) return;
    setTab(tab as 'fulfilment' | 'edit');
  }, []);

  return (
    <Section
      title={order?.order?.id
        ? `${localize(localeContentKeys.title)} (# ${order?.order?.id})`
        : localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      <Tabs
        setTab={handleSetTab}
        tabObjects={[
          {
            tabName : 'fulfilment',
            tabContent : (
              <OrderFulfilment order={initialOrder} />
            )
          },
          {
            tabName : 'edit',
            tabContent : (
              <EditOrder
                order={initialOrder}
                active={tab === 'edit'}
              />
            )
          },
        ]}
      />
      <OrderTotals order={initialOrder} mode={tab}/>
    </Section>
  );
}

const OrderDetails = ({ order, ...props } : OrderDetailsProps) => {
  const { evaluateOptions } = useOrders();

  const validateOrder = useCallback(
    (order : ProjectedOrder) : { [key : string] : string } | null => {
      const { errors, valid } = evaluateOptions({ ...order, service : null });
      if (valid) return null;
      return {
        ...(errors.address && {
          address : localize(localeFormKeys.errors.invalidAddress),
        }),
        ...(errors.serviceChannel && {
          serviceChannel : localize(
            localeFormKeys.errors.invalidServiceChannel
          ),
        }),
        ...(errors.location && {
          location : localize(localeFormKeys.errors.invalidLocation),
        }),
        ...(errors.timeSlot && {
          timeSlot : localize(localeFormKeys.errors.invalidTimeSlot),
        }),
      }
    },
    [evaluateOptions],
  );

  const requireFulfillment = useCallback(
    (order : ProjectedOrder) : { [key : string] : string } | null => {
      const { addresses, locations } = evaluateOptions({
        ...order,
        service : null,
      });
      if (!order.address && !order.location) return {
        address : localize(localeFormKeys.errors.fulfillmentRequired),
        location : localize(localeFormKeys.errors.fulfillmentRequired),
      };
      if (
        !addresses.length &&
        !!locations.length &&
        !order.location
      ) return {
        location : localize(localeFormKeys.errors.locationRequired),
        ...(!!order.address && {
          address : localize(localeFormKeys.errors.addressNotAllowed),
        }),
      };
      if (
        !!addresses.length &&
        !locations.length &&
        !order.address
      ) return {
        address : localize(localeFormKeys.errors.addressRequired),
        ...(!!order.location && {
          location : localize(localeFormKeys.errors.locationNotAllowed),
        }),
      };
      return null;
    },
    [evaluateOptions],
  );

  return (
    <FormProvider
      init={order}
      editingInit={false}
      validators={[
        validateOrder,
        requireFulfillment,
      ]}
    >
      <OrderDetailsControl {...props} order={order} />
    </FormProvider>
  );
}

export default OrderDetails;
