import React, { useCallback, useState } from 'react';

import { Assembly, Collection } from '#types';

import { FormProvider } from '#context/FormContext';

import { useFormContext } from '#hooks/useForm';
import useOptions from '#hooks/useOptions';
import useNotifications from '#hooks/useNotifications';

import { settings } from '#materials';
import Button from '#materials/Button';
import Icon from '#materials/Icon';

import CollectionForm from '#components/assemblies/CollectionForm';

import { today } from '#utils/date';
import locale, { localize } from '#utils/locale';

interface CreateCollectionProps {
  assembly : Assembly;
}

interface CreateCollectionControlProps extends CreateCollectionProps {
  collection : Collection;
}

const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.collections;

const CreateCollectionControl = ({
  collection : init,
  assembly
} : CreateCollectionControlProps) => {
  const { state: collection, reset } = useFormContext<Collection>();
  const { createAssemblyCollection } = useOptions();
  const { createNotification } = useNotifications();

  const handleSave = useCallback(async () => {
    if(!collection) return;

    const newCollection = await createAssemblyCollection(assembly, collection);

    if (newCollection) {
      createNotification({
        key: 'create-collection-success',
        message: localize(localeNotificationKeys.created.success),
        colour: settings.colours.alert.primary,
        icon: <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    } else {
      createNotification({
        key: 'create-collection-error',
        message: localize(localeNotificationKeys.created.error),
        colour: settings.colours.alert.alert,
        icon: <Icon icon={settings.svgIcons.shoppingBag} />,
      })
    }
  }, [collection, createAssemblyCollection, createNotification, assembly]);

  const handleCancel = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <>
      <>
        <Button
          onClick={handleSave}
        >
          {localize(localeButtonKeys.save)}
        </Button>
        <Button
          onClick={handleCancel}
          colour={settings.colours.button.alert}
        >
          {localize(localeButtonKeys.cancel)}
        </Button>
      </>
      <CollectionForm collection={collection || init} />
    </>
  );
}

const CreateCollectionForm = ({
  assembly
} : CreateCollectionProps) => {
  const { generateDefaultCollection } = useOptions();

  const [collection] = useState({
    ...generateDefaultCollection(),
    name : `${assembly.name} Collection`,
    starting : today(),
  });

  return (
    <FormProvider init={collection} editing={true}>
      <CreateCollectionControl
        collection={collection}
        assembly={assembly}
      />
    </FormProvider>
  );
}

export default CreateCollectionForm;
